import React, { Component } from "react";
import axios from "axios";
import "./Multiplechoice.css";
import Progress from "./Progress";
import Question from "./Question";
import Questionthree from "./Questionthree";
import QuestionMCSentence from "./QuestionMCSentence";
import CheckmarksThree from "./CheckmarksThree";
import Teachtranslation from "./Teachtranslation";
import WordBank from "./WordBank";
import ColorCodedTranslation from "./ColorCodedTranslation";
import { UserConsumer } from "./User/User.js";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import piaw from "../Images/piaw copy.png";
import Confetti from "react-confetti";
import correctsound from "./app_content/navigation_pages_content/correct.wav";
import flipsound from "./app_content/navigation_pages_content/flip-page.wav";
import wrongsound from "./app_content/navigation_pages_content/incorrect.wav";
import unlockaudio from "./app_content/navigation_pages_content/correct.wav";
import dialogue_africa_logo from "../Images/da-logo-lineart.png";
import dialogue_twi_logo from "../Images/dialogue-africa-ghana-twi-logo.png";
import dialogue_yoruba_logo from "../Images/dialogue-africa-nigeria-yoruba-logo.png";
import dialogue_ga_logo from "../Images/dialogue-africa-ghana-ga-logo.png";
import dialogue_igbo_logo from "../Images/dialogue-africa-nigeria-igbo-logo.png";
import dialogue_swahili_logo from "../Images/dialogue-africa-learn-swahili-tanzania-kenya-logo.png";

import Questionfour from "./Questionfour";
import QuestionCard from "./QuestionCard";
import SocialShare from "./SocialShare.js";
import loader2 from "./app_content/navigation_pages_content/images/animat-pencil-color.gif";
//"./app_content/navigation_pages_content/images/ring-oy.gif";
//
import darkstar from "./app_content/navigation_pages_content/images/darkstar.png";
import goldstar from "./app_content/navigation_pages_content/images/goldstar.png";
import { ReactTimeoutButton } from "react-timeout-button";
import styled from "styled-components";
import { Button, Header, Icon, Modal, Label } from "semantic-ui-react";

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
const StyledTimeoutButton1 = styled(ReactTimeoutButton)`
  background-color: #5cdb56;
  cursor: pointer;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font: 500 15px/20px "museo-sans-rounded", sans-serif;
  font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode,
    Lucida Sans, Tahoma, sans-serif;
  font-size: 15px;
  font-weight: 700;
  border-radius: 100px;
  margin-left: 20px;
`;
const StyledTimeoutButton2 = styled(StyledTimeoutButton1)`
  margin-top: 33px;
  margin-bottom: 10px;
  margin-right: 20px;
  background-color: #8d5ce0;
`;

class MultiplechoiceGeneral extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lessonDataFetchComplete: false,
      hex_adjusted: "",
      myhover: false,
      questionLikeInit: false,
      questionLikeList: {},
      questionLike: false,
      card_faved_map: [],
      missedWords: {
        word_question_type_map: [],
      },
      culture_card:
        false && this.props.lesson_number == 34 && this.props.language == null,
      initialQuizScore: 0,
      initialQuizTotalQuestion: 0,
      reviewedMissedWords: false,
      stars: 0,
      activated: false,
      score: 0,
      currentQuestion: 1,
      currentQuestionObject: "",
      choiceSelected: false,
      answerSubmitted: false,
      answerCorrect: false,
      submittedOrSkippedAnswersCount: 0, // TODO: delete - not read anywhere.
      totalQuestions: 5, // > 0 as placeholder before actual number is fetched.
      questionsToIgnore: 0,
      selectedChoice: "",
      checkmark: "",
      lesson_number: this.props.lesson_number,

      // language: this.props.language == null ? "twi" : (this.props.language == "general" ? (this.props.location.state ? this.props.location.state.language : null ): this.props.language),
      lesson_type: this.props.lesson_type,
      language: this.getLanguage(),
      lesson_type: this.props.lesson_type,
      // QUESTIONS
      questionsauto: [],
      apivalue: this.props.contextapivalue,
      redirectP: false,
      //Proverb info:
      proverb_native_text: "",
      proverb_direct_transation: "",
      proverb_interpretation: "",
      proverb_native_sound: "",
      proverb_opened: false,
      unlock_sound: unlockaudio,
      modalOpen: false,
      timerCountdownActive1: true,
      //Submitted Answers type
      submitted_answer_correct: false,
      select_course_link: this.getSelectCourseLink(),
      // this.props.select_course_link == null
      //   ? "/select_course"
      //   : this.props.select_course_link,
      currentThemeColor: "",
    };

    //Bind functions to the right context.
    // Articles on bind versus arrow functions
    //https://medium.com/@nikolalsvk/loosing-bind-this-in-react-8637ebf372cf
    //https://medium.com/dailyjs/demystifying-memory-usage-using-es6-react-classes-d9d904bc4557
    this.toggleActivation = this.toggleActivation.bind(this);
    this.toggleSubmission = this.toggleSubmission.bind(this);
    this.incrementQuestionNumber = this.incrementQuestionNumber.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.incrementSubmittedOrSkippedAnswersCount = this.incrementSubmittedOrSkippedAnswersCount.bind(
      this
    );
    this.incrementScore = this.incrementScore.bind(this);
    this.incrementQuestionsToIgnore = this.incrementQuestionsToIgnore.bind(
      this
    );
    this.setSelectedChoice = this.setSelectedChoice.bind(this);
    this.resetSelectedChoice = this.resetSelectedChoice.bind(this);
    this.setCurrentQuestionObject = this.setCurrentQuestionObject.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.checkAnswerWrap = this.checkAnswerWrap.bind(this);
    this.restartAll = this.restartAll.bind(this);
    this.resetPerQuestion = this.resetPerQuestion.bind(this);
    this.getNextButtonStyle = this.getNextButtonStyle.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
    this.calculateStars = this.calculateStars.bind(this);
    this.rating = this.rating.bind(this);
    this.sendMissedWords = this.sendMissedWords.bind(this);
    this.quizMissedWords = this.quizMissedWords.bind(this);
    this.processWrongAnswers = this.processWrongAnswers.bind(this);
    this.aggregateMissedWord = this.aggregateMissedWord.bind(this);
    this.skipButtonDisplay = this.skipButtonDisplay.bind(this);
    this.correctAudio = this.correctAudio.bind(this);
    this.wrongAudio = this.wrongAudio.bind(this);
    this.audio = new Audio();
    this.fetchAndUnlockProverb = this.fetchAndUnlockProverb.bind(this);
    this.setProverbComponents = this.setProverbComponents.bind(this);
    this.getProverbNativeSound = this.getProverbNativeSound.bind(this);
    this.getProverbNativeText = this.getProverbNativeText.bind(this);
    this.getProverbDirectTranslation = this.getProverbDirectTranslation.bind(
      this
    );
    this.getProverbInterpretation = this.getProverbInterpretation.bind(this);
    this.playProverbNativeAudio = this.playProverbNativeAudio.bind(this);
    this.proverbUnlockGrant = this.proverbUnlockGrant.bind(this);
    this.proverbPointProcessing = this.proverbPointProcessing.bind(this);
    this.postQuizProcessing = this.postQuizProcessing.bind(this);
    this.playSound = this.playSound.bind(this);
    // this.playUnlockSound = this.playUnlockSound.bind(this);
    this.closeProverb = this.closeProverb.bind(this);
    this.closeProverbAndHandleCompletion = this.closeProverbAndHandleCompletion.bind(
      this
    );
    this.stopAudio = this.stopAudio.bind(this);
    this.checkWordBankAnswer = this.checkWordBankAnswer.bind(this);
    this.checkSelectedChoiceVersusCorrectAnswer = this.checkSelectedChoiceVersusCorrectAnswer.bind(
      this
    );
    this.checkAnswerForQuestionType = this.checkAnswerForQuestionType.bind(
      this
    );
    this.getTimerForQuestionType = this.getTimerForQuestionType.bind(this);
    this.showSubmitButton = this.showSubmitButton.bind(this);
    this.submitAnswer = this.submitAnswer.bind();
  }
  static propTypes = {
    size: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  };

  componentDidMount() {
    this.fetchQuestionLikes();
    // this.fetchLessonData();
    this._ismounted = true;

    // this.updateQuestionLike();
  }
  getSelectCourseLink = () => {
    // need to call getLanguage because this.state not constructed yet.
    let language = this.getLanguage();
    // console.log("langs: mg-get-sclink ", language);
    return this.props.contextapivalue.select_course_route(language);
  };
  getLanguage = () => {
    // if null then twi. if not null and not "general" then this.props.language
    // if not null and general then check for location state and pick lang variable
    // if no location state even when "general"(no real case), then go with null.
    // console.log("langs: ", this.props.language);
    // console.log("langs: ", this.props.location.state);
    if (this.props.language == null) {
      return "twi";
    } else if (this.props.language == "general") {
      if (this.props.location.state) {
        return this.props.location.state.language;
      }
      return null;
    } else {
      return this.props.language;
    }
  };
  retryButtonDisplay = () => {
    // const question = this.state.currentQuestionObject;
    // let is_culture_quiz = question.card != null;
    // need some indication quiz is a culture quiz
    let is_culture_quiz = true;
    if (is_culture_quiz) {
      return "Replay";
    } else {
      return "Retry";
    }
  };
  updateQuestionLike = () => {
    const question = this.state.currentQuestionObject;
    // console.log("likes question obj: ", question);
    // console.log("likes cq 1: ", this.state.currentQuestion);
    // If its a culture card
    if (question.card != null) {
      let card_id = question.card.id;
      // get fave status
      let currently_faved = this.state.questionLikeList[card_id];

      // Add card to like list if it's part of quiz info but doesn't exist as faved
      // let card_id_str = card_id.toString()
      if (!(card_id in this.state.questionLikeList)) {
        currently_faved = false;
        let qll = this.state.questionLikeList[card_id];
        qll[card_id] = currently_faved;
        this.setState({ questionLikeList: qll });
      }
      // console.log("likes cq 2: ", this.state.currentQuestion);
      // console.log("favegames: likes qll: ", this.state.questionLikeList);
      // console.log("likes card_id: ", card_id);
      // console.log("favegames: likes cfav: ", currently_faved);
      this.setState({ questionLike: currently_faved, questionLikeInit: true });
      // console.log("likes end update question if clause");

      return;
    }
    // console.log("likes end update question else");
  };
  lessonLog(data) {}
  card_likes = async (lesson_id) => {
    var path = `get_card_faves/${lesson_id}/`;
    return this.state.apivalue.getUsingToken(path);
  };
  fetchQuestionLikes = async () => {
    let res = await this.card_likes(this.state.lesson_number);
    // console.log("card_likes res:", res);
    let json = await res.json();
    // console.log("card_likes json:", json);
    // let fson = { 3: true, 7: true, 2: true, 1: false, 5: false, 6: false };
    // json = fson;
    // console.log("favegame: likes json fake: ", fson);
    // console.log("favegame: likes json: ", json);
    this.setState({ questionLikeList: json }, async () => {
      await this.fetchLessonData();
      await this.updateQL(this.state.questionLikeList);
    });
    // json = this.state.questionLikeList;

    // Set first question like state
  };
  updateQL = async (questionLikeDict) => {
    if (this.state.questionLikeInit == true) {
      return;
    }
    // let cq = this.state.currentQuestion;
    // let qll = questionLikeDict;
    // let id_cfav_tuple = qll[cq];
    // if (id_cfav_tuple == null) {
    //   return;
    // }
    // let cfav = id_cfav_tuple[1];
    // this.setState({ questionLike: cfav, questionLikeInit: true });

    let question = this.state.currentQuestionObject;
    if (question.card != null) {
      // let currently_faved = questionLikeDict[this.state.currentQuestion];
      // console.log("favegame: qldict: ", questionLikeDict);
      let cid = question.card.id;
      let str_cid = cid.toString();
      let currently_faved = questionLikeDict[str_cid];
      // console.log("favegame: card id ", question.card.id);
      // console.log("favegame: currently_faved: ", currently_faved);
      this.setState({ questionLike: currently_faved, questionLikeInit: true });
    }
  };
  componentWillUnmount() {
    this._ismounted = false;
  }
  setCurrentThemeColor = (theme) => {
    if (!theme) {
      theme = "#42b83d";
    }
    this.setState({
      currentThemeColor: theme,
      hex_adjusted: this.hex_adjust(theme, -20),
    });
  };
  getLesson(language, lesson_number, lesson_type) {
    if (language == null) {
      language = "twi";
    }
    path = `random_vocab_lesson/${lesson_number}/${language}/?format=json`;

    const is_blended_lesson =
      lesson_number == 81 /* igbo_cities_puzzle */ ||
      lesson_number == 80 /* igbo_artists_puzzle */ ||
      lesson_number == 79 /* naija_artists_puzzle */ ||
      lesson_number == 78 /* ghana_games_major_cities */ ||
      lesson_number == 77 /* culture_food_ghana */ ||
      lesson_number == 76 /* ga_greetings_phrases */ ||
      lesson_number == 74 /* tenses_4 */ ||
      lesson_number == 73 /* tenses_3 */ ||
      lesson_number == 72 /* wellbeing_swahili */ ||
      lesson_number == 71 /* tenses_2 */ ||
      lesson_number == 70 /* tenses_1 */ ||
      lesson_number == 69 /* women_in_ghana  */ ||
      lesson_number == 68 /* haggling_blended  */ ||
      lesson_number == 66 /* twi_basics_3_blended  */ ||
      lesson_number == 65 /* twi_basics_2_blended  */ ||
      lesson_number == 64 /* body_parts_5_heated_emotion  */ ||
      lesson_number == 63 /* greetings_fixed_vocab  */ ||
      lesson_number == 62 /* singular pronouns blended  */ ||
      lesson_number == 61 /* body parts 4 wit blended  */ ||
      lesson_number == 60 /* romance_3_cute_idioms  */ ||
      lesson_number == 59 /* object pronoun blended 1 */ ||
      lesson_number == 57 /* subject pronoun blended 1 */ ||
      lesson_number == 55 /* twi basics blended 1 */ ||
      lesson_number == 54 /* igbo family blended 1 */ ||
      lesson_number == 52 /* igbo greetings blended 1 */ ||
      lesson_number == 49 ||
      lesson_number == 44 ||
      lesson_number == 19 ||
      lesson_number == 41 ||
      lesson_number == 40 ||
      // lesson_number == 11 ||
      lesson_number == 34 ||
      lesson_number == 38 ||
      lesson_number == 53 /* culture 2 test*/ ||
      lesson_number == 36 ||
      lesson_number == 37 ||
      lesson_number == 35 ||
      lesson_number == 26 ||
      lesson_number == 4 ||
      lesson_number == 29 ||
      lesson_number == 27;
    const is_twi_lesson = language == null || language == "twi";

    if (/*is_twi_lesson && */ is_blended_lesson) {
      var path = `lesson/${lesson_number}/${language}/?format=json`;
    }
    return this.state.apivalue.getUsingToken(path);
  }
  fetchLessonData = async () => {
    // var uq = this.updateQL
    this.getLesson(
      this.state.language,
      this.state.lesson_number,
      this.state.lesson_type
    )
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            if (this.state.reviewedMissedWords == false) {
              //Prevents original question set fetch response from overwritting
              //question set response of missed words.
              this.setState(
                {
                  questionsauto: json,
                }
                // this.updateQL(this.state.questionLikeList)
              );

              var quiz_len2 = Object.keys(this.state.questionsauto).length;
              this.setState({
                totalQuestions: quiz_len2,
                lessonDataFetchComplete: true,
              });
            }
          });
        } else {
          //Need to display some informative message.
          alert(
            "Lesson retrieval failed. Session may have timed out. Re-login / try again."
          );
        }
      })
      .catch((error) => {
        //Need to display some informative message.
        console.log(error);
      });
  };
  proverbPointProcessing() {
    if (this.proverbUnlockGrant()) {
      this.fetchAndUnlockProverb();
    }
  }
  proverbUnlockGrant() {
    if (this.state.stars >= 3) {
      return true;
    } else {
      return false;
    }
  }
  proverbData(language) {
    if (language == null || language == "undefined") {
      language = "twi";
    }
    var path = `get_proverb/${language}/`;
    return this.state.apivalue.getUsingToken(path);
  }
  fetchAndUnlockProverb() {
    this.proverbData(this.state.language)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            var nt = json.twi_text;
            var dt = json.direct_english_translation;
            var i = json.english_interpretation;
            var na = json.twi_audio;
            this.setProverbComponents(nt, dt, i, na);
          });
        } else {
          console.error("Failed to retrieve proverb");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  setProverbComponents(nt, dp, i, na) {
    this.setState({
      proverb_native_text: nt,
      proverb_direct_transation: dp,
      proverb_interpretation: i,
      proverb_native_sound: na,
    });
  }
  playProverbNativeAudio() {
    var pns = this.getProverbNativeSound();
    this.playSound(pns);
  }
  openProverbModal = () => {
    if (this.state.modalOpen == true) {
      return;
    }
    this.setState({ modalOpen: true });
    // delay for audio autoplay after modal opens
    let timerlen = 600;
    return setTimeout(
      function () {
        this.playProverbNativeAudio();
      }.bind(this),
      timerlen
    );
  };
  returnProverbHeader = () => {
    return (
      <div class="modal-header" id="proverbHeading">
        <h1>
          <i class="fas fa-gift"></i>{" "}
          {this.state.culture_card
            ? "Unlocked Culture Card"
            : "Unlocked Proverb"}
        </h1>
        {this.state.timerCountdownActive1 ? <div class="timer"></div> : <></>}
      </div>
    );
  };
  returnProverbModalButton = (ctx, select_course_jump_link) => {
    let button_text = "Unlock Proverb";
    let modal_size = "medium";
    let modal_top = "30%";
    if (this.state.culture_card) {
      button_text = "Unlock Culture Card";
      modal_size = "medium";
      modal_top = "10%";
    }
    return (
      <>
        {this.state.proverb_opened ? (
          <></>
        ) : (
          <>
            {0 ? (
              <div>
                {" "}
                {this.props.language}
                {/* == 34 && this.props.language == "twi"*/}{" "}
              </div>
            ) : (
              <>
                <div onClick={this.openProverbModal}>
                  <StyledTimeoutButton2
                    // timeout={3500}
                    text={button_text}
                    // overlay={true} // dark color
                    onTimeout={() => {
                      this.openProverbModal();
                    }}
                  />
                </div>
                <Modal
                  style={{
                    top: modal_top,
                    background: "none",
                    boxShadow: "none",
                  }}
                  open={this.state.modalOpen}
                  centered={true}
                  size={modal_size}
                  onClose={() => {}}
                  closeOnDimmerClick={false}
                >
                  <div className="header">{this.returnProverbHeader()}</div>
                  <Modal.Content>
                    <Modal.Description>
                      <div id="pw">
                        {this.state.culture_card ? (
                          <img class="piaw" src={piaw} />
                        ) : (
                          <> {this.returnProverbBody()} </>
                        )}
                        <>
                          {this.returnProverbFooter(
                            ctx,
                            select_course_jump_link
                          )}
                        </>
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </>
            )}
          </>
        )}
      </>
    );
  };
  returnProverbBody = () => {
    return (
      <div class="modal-body">
        <div
          style={{ cursor: "pointer" }}
          onClick={this.playProverbNativeAudio}
        >
          <h1 style={{ textAlign: "center" }}>
            <i id="nicegreen" class="fas fa-seedling"></i>
          </h1>
          <h4 id="proverbsubheading">
            {this.state.proverb_native_sound != null ? (
              <i
                id="myletteraudio"
                // onClick={this.playProverbNativeAudio}
                class="fas fa-volume-up"
              ></i>
            ) : (
              <div></div>
            )}{" "}
            {this.getProverbNativeText()}
          </h4>
          <h4 id="proverbsubheading">{this.getProverbDirectTranslation()}</h4>
          <h4 id="proverb">{this.getProverbInterpretation()}</h4>
        </div>
      </div>
    );
  };
  displayTimerButton = (bool) => {
    this.setState({ timerCountdownActive1: bool });
  };
  returnProverbFooter = (ctx, select_course_jump_link) => {
    return (
      <div class="modal-footer">
        {this.state.timerCountdownActive1 ? (
          <button
            onClick={() => this.displayTimerButton(false)}
            type="button"
            data-dismiss="modal"
            class="proverbbuttons buttonpurple"
          >
            <p>
              <i class="far fa-pause-circle continuearrow"></i> Pause{" "}
            </p>
          </button>
        ) : (
          <></>
        )}

        {this.state.timerCountdownActive1 ? (
          <div
            onClick={(e) =>
              this.closeProverbAndHandleCompletion(
                e,
                ctx.user,
                select_course_jump_link
              )
            }
          >
            <StyledTimeoutButton1
              timeout={15000}
              text=" Continue "
              overlay={true}
              overlayOpacity={0.15}
              onTimeout={(e) =>
                this.closeProverbAndHandleCompletion(
                  e,
                  ctx.user,
                  select_course_jump_link
                )
              }
            />
          </div>
        ) : (
          <button
            onClick={(e) =>
              this.closeProverbAndHandleCompletion(
                e,
                ctx.user,
                select_course_jump_link
              )
            }
            type="button"
            data-dismiss="modal"
            class="proverbbuttons buttonbrightgreen"
          >
            <p>
              <i class="far fa-arrow-alt-circle-right continuearrow"></i>{" "}
              Continue{" "}
            </p>
          </button>
        )}
      </div>
    );
  };

  playUnlockSound = () => {
    var us = this.getUnlockSound();
    this.playSound(us);
  };
  playSound(sound_file) {
    this.audio.src = sound_file;
    var promise = this.audio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  stopAudio() {
    var promise = this.audio.pause();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
    this.audio.currentTime = 0;
  }
  closeProverb() {
    this.stopAudio();
    this.setState({
      proverb_opened: true,
    });
  }
  closeProverbAndHandleCompletion(e, input_user, jump_link) {
    this.closeProverb();
    this.handleCompleteQuiz(e, input_user, jump_link);
  }
  getUnlockSound() {
    return this.state.unlock_sound;
  }
  getProverbNativeSound() {
    return this.state.proverb_native_sound;
  }
  getProverbNativeText() {
    return this.state.proverb_native_text;
  }
  getProverbDirectTranslation() {
    return this.state.proverb_direct_transation;
  }
  getProverbInterpretation() {
    return this.state.proverb_interpretation;
  }
  setCurrentQuestionObject(questionObject) {
    this.setState({ currentQuestionObject: questionObject });
  }
  setCheckmarkCorrect() {
    this.setState({ checkmark: "correct" });
  }
  setCheckmarkWrong() {
    this.setState({ checkmark: "wrong" });
  }
  checkAnswerWrap() {
    this.checkAnswer();
  }
  checkAnswerWrap2() {
    this.checkAnswer();
  }
  correctAnswerAudio(audio) {
    this.audio.src = audio;
    this.audio.play();
  }
  correctAudio() {
    this.audio.src = correctsound;
    this.audio.play();
  }
  wrongAudio() {
    this.audio.src = wrongsound;
    this.audio.play();
  }

  checkSelectedChoiceVersusCorrectAnswer(correct_answer) {
    return this.state.selectedChoice === correct_answer;
  }
  checkWordBankAnswer(ctx) {
    // console.log("call childCheckAnswer ...function in child component");
    // Check from child component whether submitted answer is correct ??
    // console.log("my_cca this: ", this);
    // descendant function made available to
    // ancestor
    // By the time this code is called (after TranslateQuestion mounts), this.childCheckAnswer has been initiated with a checkAnswer() function from a (grand-)child (TranslateQuestion) component.
    // cca exists for two reasons:
    // 1. to set the state below and have the described effect
    // Possible Resolution/Alternative: whatever triggers checkWordBankAnswer to be called from TranslateQuestion. can just trigger the checkAnswer function to be called & change answerCorrect locally.
    // 2. as a return value for this function.
    // when sumbit answer is clicked. check answer is called (wiithout parameter)
    var cca = ctx.childCheckAnswer();

    // This state change causes TranslateQuestion::getUserAnswerColor() to return correct which turns the user answers in the wordbank green.
    this.setState({ submitted_answer_correct: cca }, () => {
      // console.log(
      //   "my_cca submitted_answer_correct: ",
      //   this.state.submitted_answer_correct
      // );
    });
    // console.log("checkWordBankAnswer return val cca: ", cca);
    return cca;
    // Goal: When submit is called, we want TQ:checkAnswer to be called & TQ:answerCorrect local variable to turn true if answer is correct.
    // and checkAnswerForQuestionType(qstr) point in check answer to return true
  }
  checkAnswerForQuestionType(qstr) {
    //Handle multiple choice questions
    if (
      qstr === "match-word-picture" ||
      qstr === "match-sound-picture" ||
      qstr === "match-textimage-text" ||
      qstr === "translate-text-text" ||
      qstr === "match-text-with-response"
    ) {
      var correct_answer = this.state.currentQuestionObject.correct_answer;
      return this.checkSelectedChoiceVersusCorrectAnswer(correct_answer);
    } else if (
      qstr === "word-match-picture" || //auto-genrated-choices
      qstr === "word-sound-picture" ||
      qstr === "word-match-textimage" ||
      qstr === "word-translate-text"
    ) {
      var correct_answer = this.state.currentQuestionObject
        .auto_generated_choices.correct_answer_option;
      return this.checkSelectedChoiceVersusCorrectAnswer(correct_answer);
    } else if (
      qstr === "word-bank-twi-to-english" ||
      qstr === "word-bank-english-to-twi" ||
      qstr === "word-bank-sound-twi-to-twi" ||
      qstr === "word-bank-sound-twi-to-english" ||
      qstr === "card-games-activities"
    ) {
      return this.checkWordBankAnswer(this);
    }
    return false;
  }
  getTimerForQuestionType(qstr) {
    let timerlen = 2100;
    if (
      qstr === "match-textimage-text" ||
      qstr === "translate-text-text" ||
      qstr === "word-match-textimage" ||
      qstr === "word-translate-text"
    ) {
      timerlen = 2200;
    } else if (
      qstr === "match-word-picture" ||
      qstr === "word-match-picture" || //auto-genrated-choices
      qstr === "card-games-activities"
    ) {
      timerlen = 2700;
    } else if (
      qstr === "word-sound-picture" ||
      qstr === "match-sound-picture" //auto-genrated-choices
    ) {
      timerlen = 2100;
    }
    return timerlen;
  }
  checkAnswer() {
    var timerlen = 0;
    this.incrementSubmittedOrSkippedAnswersCount();
    const qstr = this.state.currentQuestionObject.question_type;
    if (this.checkAnswerForQuestionType(qstr)) {
      this.correctAudio();
      this.incrementScore();
      this.setCheckmarkCorrect();
      timerlen = this.getTimerForQuestionType(qstr);
      this.setState({
        answerSubmitted: true,
        answerCorrect: true,
      });
      //setTimeout may not work outside return
      return setTimeout(
        function () {
          this.nextQuestion();
        }.bind(this),
        timerlen
      );
    } else {
      this.wrongAudio();
      this.processWrongAnswers(qstr);
      this.setCheckmarkWrong();
      this.setState({ answerSubmitted: true });
      return;
    }
  }
  processWrongAnswers(qstr) {
    var has_word = "";
    var has_phrase = "";
    var word_id = "";
    var phrase_id = "";
    if (
      qstr === "match-word-picture" ||
      qstr === "match-sound-picture" ||
      qstr === "match-textimage-text" ||
      qstr === "translate-text-text"
    ) {
      has_word = "true";
      has_phrase = "false";
      // Record info about wrongly answered questions
      var correct_ans = this.state.currentQuestionObject.correct_answer;

      this.state.currentQuestionObject.choices.map((choice) => {
        if (choice.multiple_choice_option === correct_ans) {
          word_id = choice.word["english_text"];
        }
      });
      // console.log("wrong_word: ", word_id);

      this.aggregateMissedWord(has_word, has_phrase, word_id, phrase_id, qstr);
    } else if (
      qstr === "word-match-picture" || //auto-genrated-choices
      qstr === "word-sound-picture" ||
      qstr === "word-match-textimage" ||
      qstr === "word-translate-text"
    ) {
      has_word = "true";
      has_phrase = "false";

      // Record info about wrongly answered questions
      word_id = this.state.currentQuestionObject.word.english_text;

      this.aggregateMissedWord(has_word, has_phrase, word_id, phrase_id, qstr);
    } else if (
      qstr === "word-bank-twi-to-english" || //auto-genrated-choices
      qstr === "word-bank-english-to-twi" ||
      qstr === "word-bank-sound-twi-to-twi" ||
      qstr === "word-bank-sound-twi-to-english"
    ) {
      has_word = "false";
      has_phrase = "true";
      // Record info about wrongly answered questions
      phrase_id = this.state.currentQuestionObject.phrase.id;
      var choices = this.state.currentQuestionObject.auto_generated_choices
        .choices;
      // console.log("wrong_word: ", word_id);
      this.aggregateMissedWord(has_word, has_phrase, word_id, phrase_id, qstr);
    }
  }
  resetSelectedChoice() {
    this.setState({ selectedChoice: "" });
  }
  setSelectedChoice(choiceId) {
    // console.log("called mc setSelectedChoice");
    // console.log(
    //   "correct answer current questionobj",
    //   this.state.currentQuestionObject.correct_answer
    // );
    if (this.state.answerSubmitted === false) {
      // console.log("called mc setSelectedChoice, answer not yet submitted");
      // console.log("choice id", choiceId);
      this.setState({ selectedChoice: choiceId }, () => {
        // console.log("selectedChoice(<- choiceID): ", this.state.selectedChoice);
        this.checkAnswer();
      });
      this.setState({ choiceSelected: true });
    }
  }
  //toggleActivation can be used for correct & wrong signs
  //but dangerous, if one thing goes off, the mistake will persist
  //add resetActivaton() to be safer
  toggleActivation() {
    if (this.state.activated === true) {
      this.setState({ activated: false });
    } else {
      this.setState({ activated: true });
    }
  }
  toggleQuestionLike = () => {
    this.setState({ questionLike: !this.state.questionLike });
  };
  toggleSubmission() {
    if (this.state.answerSubmitted === true) {
      this.setState({ answerSubmitted: false });
    } else {
      this.setState({ answerSubmitted: true });
    }
  }
  resetActivaton() {
    this.setState({ activated: true });
  }
  handleChange(e) {
    //console.log(e.target.value);
    //this.setState({name: e.target.value});
    //e.target -> input box
    //e.target.value -> value in input box
  }
  //Dialogue Quiz
  setScore(s) {
    this.setState({ score: s });
  }
  incrementScore() {
    var inc = this.state.score + 1;
    this.setState({ score: inc });
  }
  incrementQuestionsToIgnore() {
    var inc = this.state.questionsToIgnore + 1;
    this.setState({ questionsToIgnore: inc });
  }
  incrementSubmittedOrSkippedAnswersCount() {
    var inc = this.state.submittedOrSkippedAnswersCount + 1;
    this.setState({ submittedOrSkippedAnswersCount: inc });
  }
  setCurrentQuestion(cq) {
    this.setState({ currentQuestion: cq });
  }
  incrementQuestionNumber() {
    var inc = this.state.currentQuestion + 1;
    this.setState({ currentQuestion: inc });
  }
  resetPerQuestion() {
    this.setState({
      checkmark: "",
      choiceSelected: false,
      answerSubmitted: false,
      answerCorrect: false,
      currentQuestionObject: "",
      selectedChoice: "",
      questionLikeInit: false,
    });
    this.resetSelectedChoice();
  }

  nextQuestion() {
    //before  question number incremented
    if (
      this.state.currentQuestion == this.state.totalQuestions &&
      this.state.reviewedMissedWords == false
    ) {
      this.postQuizProcessing();
    }
    // this.stopAudio(); // no effect

    this.incrementQuestionNumber();
    this.resetPerQuestion();
  }
  postQuizProcessing() {
    this.proverbPointProcessing();
    // if there are card faves save them.
    // this.sendPlayedCards().catch(alert);
  }
  newQuestionLikesFromCardFavesMap = () => {
    let cfm = this.state.card_faved_map;
    let newList = {};
    for (var i = 0; i < cfm.length; i++) {
      let dict = cfm[i];
      let card_id = dict["card_id"];
      let faved = dict["faved"];
      newList[card_id] = faved;
    }
    return newList;
  };
  hex_adjust = (color, amount) => {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  };
  skipButtonDisplay() {
    var text_display = "";
    var display_button = true;
    var question_type = this.state.currentQuestionObject.question_type;
    var question_category = this.state.currentQuestionObject.question_category;
    if (question_category === "card" || question_category === "test_card") {
      text_display = "View Next Card";
      let default_theme_color = "#42b83d";
      let theme_color = default_theme_color;

      // switch (question_type) {
      //   case "card-quotes-sayings":
      //     theme_color = "#8d5ce0";
      //     break;
      //   case "card-people-advancements":
      //     theme_color = "#fc9d03"; //"#8d5ce0";
      //     break;
      //   case "card-culture-knowledge":
      //     theme_color = "#880861";
      //     break;
      //   case "card-places-experiences":
      //     theme_color = "#fc9d03";
      //     break;
      //   case "card-games-activities":
      //     theme_color = "#0099cc";
      //     //"#5bc0de";
      //     break;
      //   default:
      //     theme_color = "#42b83d";
      //     // "#5cdb56";
      //     break;
      // }
      // // this.setState({ currentThemeColor: theme_color });
      // this.setCurrentThemeColor(theme_color);
      // removes skip button for wordsearch & wordbank within question card
      if (
        question_type === "word-bank-english-to-twi" ||
        question_type === "card-games-activities"
      ) {
        display_button = false;
      }
    } else if (
      question_type === "teaching-translation" ||
      question_type === "color-coded-phrases"
    ) {
      text_display = "Next";
      this.setCurrentThemeColor("#42b83d");
      // } else if(current_question_type === "word-bank-twi-to-english"){
      //   return "Check Answer"
      display_button = true;
    } else if (question_type === "word-bank-english-to-twi") {
      this.setCurrentThemeColor("#42b83d");
      display_button = false;
    }
    // else if (   current_question_type === "teaching-translation"
    //            || current_question_type === "color-coded-phrases"
    // ){
    //
    // }
    else {
      text_display = "Skip";
      display_button = false;
      //for testing
      // display_button = true;
      this.setCurrentThemeColor("#42b83d");
    }

    //Return element
    if (display_button) {
      if (question_category == "card" || question_category == "test_card") {
        return (
          <Button id="cardbutton" as="div" labelPosition="right">
            <Button
              onMouseOver={() => {
                this.setState({ myhover: true });
              }}
              onMouseOut={() => {
                this.setState({ myhover: false });
              }}
              style={{
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                backgroundColor: this.state.myhover
                  ? this.state.hex_adjusted
                  : this.state.currentThemeColor,
                color: "white",
              }}
              onClick={this.skipQuestion}
            >
              {/* {this.state.questionLike ? "Next Card" : "Next Card"} */}
              Next Card
            </Button>

            <Label
              onClick={this.toggleQuestionLike}
              style={{
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                borderColor: this.state.currentThemeColor,
              }}
              color={this.state.currentThemeColor}
              as="a"
              basic
              pointing="left"
            >
              {this.state.questionLike ? (
                <Icon name="heart" color="red" />
              ) : (
                <Icon name="heart" color="grey" />
              )}
            </Label>
          </Button>
        );
      } else {
        let tc = this.state.currentThemeColor;
        return (
          <button class="buttonstyle1 buttongreen" onClick={this.skipQuestion}>
            {text_display}
          </button>
        );
      }
    } else {
      return <div></div>;
    }
  }
  submitButtonDisplay = () => {
    // var question_type = this.state.currentQuestionObject.question_type;
    var question_category = this.state.currentQuestionObject.question_category;
    //Return element
    if (question_category == "card" || question_category == "test_card") {
      return (
        <Button id="cardbutton" as="div" labelPosition="right">
          <Button
            onMouseOver={() => {
              this.setState({ myhover: true });
            }}
            onMouseOut={() => {
              this.setState({ myhover: false });
            }}
            style={{
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
              backgroundColor: this.state.myhover
                ? this.state.hex_adjusted
                : this.state.currentThemeColor,
              color: "white",
            }}
            onClick={() => this.submitAnswer(this)}
          >
            Submit Answer
          </Button>

          <Label
            onClick={this.toggleQuestionLike}
            style={{
              borderTopRightRadius: "15px",
              borderBottomRightRadius: "15px",
              borderColor: this.state.currentThemeColor,
            }}
            color={this.state.currentThemeColor}
            as="a"
            basic
            pointing="left"
          >
            {this.state.questionLike ? (
              <Icon name="heart" color="red" />
            ) : (
              <Icon name="heart" color="grey" />
            )}
          </Label>
        </Button>
      );
    } else {
      return (
        <button
          class="buttonstyle1 buttongreen"
          onClick={() => this.submitAnswer(this)}
        >
          Submit Answer!{" "}
        </button>
      );
    }
  };
  themeColors = (color_str) => {
    return color_str;
    let added_style = "";
    switch (color_str) {
      case "blue":
        added_style = " " + "bg-info";
        break;
      case "green":
        added_style = " " + "bg-success";
        break;
      case "orange":
        added_style = " " + "bg-warning";
        break;
      case "red":
        added_style = " " + "bg-danger";
        break;
      case "grey":
        added_style = " " + "bg-secondary";
        break;
      case "brightgreen":
        added_style = " " + "mediumlightgreen";
        break;
      case "purple":
        added_style = " " + "buttonpurple";
        break;
      case "manhyia_orange":
        added_style = " " + "manhyia_orange";
        break;
      case "sankofa_purple":
        added_style = " " + "sankofa_purple";
        break;
      default:
        added_style = " " + "bg-success";
        break;
    }
    return prev_styles + added_style;
  };
  showSubmitButton() {
    const current_question_type = this.state.currentQuestionObject
      .question_type;

    if (
      current_question_type === "word-bank-twi-to-english" ||
      current_question_type === "word-bank-english-to-twi" ||
      current_question_type === "word-bank-sound-twi-to-twi" ||
      current_question_type === "word-bank-sound-twi-to-english" ||
      // removes skip button for wordsearch within question card
      current_question_type === "card-games-activities"
    ) {
      return true;
    }
    return false;
  }
  submitAnswer(thisone) {
    thisone.checkAnswer();
    const question = thisone.state.currentQuestionObject;
    if (question.card != null) {
      thisone.aggregatePlayedCards(
        question.card.id,
        thisone.state.questionLike
      );
      // console.log(
      //   `favegame: like: ${thisone.state.questionLike}, card: ${question.card.id}`
      // );
    }
  }
  flipAudio = () => {
    this.audio.src = flipsound;
    this.audio.play();
  };
  skipQuestion() {
    // Record info about skipped answered questions
    // play skip question sound
    this.flipAudio();
    const question = this.state.currentQuestionObject;
    var correct_ans = question.correct_answer;
    var word_id = "";
    question.choices.map((choice) => {
      if (choice.multiple_choice_option === correct_ans) {
        word_id = choice.word["english_text"];
      }
    });
    const qstr = question.question_type;
    const question_category = question.question_category;
    if (question.card != null) {
      this.aggregatePlayedCards(question.card.id, this.state.questionLike);
      // console.log(
      //   `favegame: like: ${this.state.questionLike}, card: ${question.card.id}`
      // );
    }
    this.processWrongAnswers(qstr);
    if (
      qstr == "teaching-translation" ||
      qstr == "color-coded-phrases" ||
      question_category == "card"
    ) {
      this.incrementQuestionsToIgnore();
    }
    this.incrementSubmittedOrSkippedAnswersCount();
    this.processQuestionLike();
    this.resetQuestionLike();
    // this.updateQuestionLike();
    this.nextQuestion();
  }
  processQuestionLike = () => {
    // NOT YET IMPLEMENTED
  };

  resetQuestionLike = () => {
    this.setState({ questionLike: false });
  };

  restartAll() {
    let newQuestionLikesList = this.state.questionLikeList;
    if (this.state.card_faved_map.length > 0) {
      newQuestionLikesList = this.newQuestionLikesFromCardFavesMap();
    }
    this.setState({
      score: 0,
      questionsToIgnore: 0,
      currentQuestion: 1,
      currentQuestionObject: "",
      choiceSelected: false,
      answerSubmitted: false,
      answerCorrect: false,
      submittedOrSkippedAnswersCount: 0,
      selectedChoice: "",
      checkmark: "",
      questionLikeList: newQuestionLikesList,
      card_faved_map: [],
    });
  }
  quizMissedWords() {
    if (this.state.reviewedMissedWords === false) {
      // intialQuizScore set in handleCompleteQuiz()
      this.setState({ initialQuizScore: this.state.score });
      this.setState({ initialQuizTotalQuestion: this.state.totalQuestions });
      this.setState({ reviewedMissedWords: true });
      this.setState({ questionsauto: [] }, () => {
        this.sendMissedWords();
        this.restartAll();
      });
    } else {
      this.restartAll();
    }
  }
  getNextButtonStyle() {
    if (this.state.checkmark === "wrong") {
      return "buttonstyle1 buttonred";
    } else {
      return "buttonstyle1 buttongreen";
    }
  }
  rating(percent) {
    if (percent > 95) return 4;
    if (percent > 70) return 3;
    if (percent > 50) return 2;
    if (percent > 25) {
      return 1;
    } else {
      return 0;
    }
  }
  calculateStars() {
    var main_score = 0;
    var percent = 0;
    var stars = 0;
    if (this.state.reviewedMissedWords == false) {
      //first time around
      main_score = this.state.score;
      var graded_questions =
        this.state.totalQuestions - this.state.questionsToIgnore;
      percent = 100 * (main_score / graded_questions);
      stars = this.rating(percent);
      this.setState({ stars: stars });
    } else {
      //second time around
      main_score = this.state.initialQuizScore;
      stars = this.state.stars;
    }

    // console.log("rating stars: ", stars);
    return stars;
  }

  renderQuestion(type_str, question) {
    this.setCurrentQuestionObject(question);
    this.updateQL(this.state.questionLikeList);
    let category = question.question_category;
    if (category == "card" || category == "test_card") {
      return (
        <div>
          <QuestionCard
            questionObject={question}
            currentQuestion={this.state.currentQuestion}
            answerSubmitted={this.state.answerSubmitted}
            //mechanism to pass child function as parameter to parent field
            setClick={(click) => (this.childCheckAnswer = click)}
            childCheckAnswer={this.childCheckAnswer}
            answerCorrect={this.state.submitted_answer_correct}
            question_type={type_str}
            questionLike={this.state.questionLike}
            toggleQuestionLike={this.toggleQuestionLike}
            setCurrentThemeColor={this.setCurrentThemeColor}
          />
        </div>
      );
    } else if (
      type_str == "match-word-picture" ||
      type_str == "word-match-picture"
    ) {
      return (
        <div>
          <Question
            questionObject={question}
            currentQuestion={this.state.currentQuestion}
            incQN={this.incrementQuestionNumber}
            incS={this.incrementScore}
            setSelectedChoice={this.setSelectedChoice}
            setCurrentQuestionObject={this.setCurrentQuestionObject}
            currentQuestionObject={this.state.currentQuestionObject}
            answerSubmitted={this.state.answerSubmitted}
            checkAnswer={this.checkAnswerWrap}
            nextQuestion={this.nextQuestion}
            auto_generated_choices={
              type_str == "word-match-picture" ? true : false
            }
            using_african_word={this.props.using_african_word}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.answerCorrect}
          />
        </div>
      );
    } else if (
      type_str == "match-textimage-text" ||
      type_str == "word-match-textimage"
    ) {
      return (
        <div>
          <Questionthree
            question={question}
            currentQuestion={this.state.currentQuestion}
            incQN={this.incrementQuestionNumber}
            incS={this.incrementScore}
            setSelectedChoice={this.setSelectedChoice}
            setCurrentQuestionObject={this.setCurrentQuestionObject}
            currentQuestionObject={this.state.currentQuestionObject}
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.answerCorrect}
            auto_generated_choices={
              type_str == "word-match-textimage" ? true : false
            }
            using_african_word={this.props.using_african_word}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.answerCorrect}
          />
        </div>
      );
    } else if (
      type_str == "translate-text-text" ||
      type_str == "word-translate-text"
    ) {
      return (
        <div>
          <QuestionMCSentence
            question={question}
            question_type_text={"Select the correct translation for : "}
            currentQuestion={this.state.currentQuestion}
            incQN={this.incrementQuestionNumber}
            incS={this.incrementScore}
            setSelectedChoice={this.setSelectedChoice}
            setCurrentQuestionObject={this.setCurrentQuestionObject}
            currentQuestionObject={this.state.currentQuestionObject}
            answerSubmitted={this.state.answerSubmitted}
            auto_generated_choices={
              type_str == "word-translate-text" ? true : false
            }
            using_african_word={this.props.using_african_word}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.answerCorrect}
          />
        </div>
      );
    } else if (type_str == "match-text-with-response") {
      return (
        <div>
          <QuestionMCSentence
            question={question}
            question_type_text={"Select the correct response"}
            currentQuestion={this.state.currentQuestion}
            incQN={this.incrementQuestionNumber}
            incS={this.incrementScore}
            setSelectedChoice={this.setSelectedChoice}
            setCurrentQuestionObject={this.setCurrentQuestionObject}
            currentQuestionObject={this.state.currentQuestionObject}
            answerSubmitted={this.state.answerSubmitted}
            auto_generated_choices={
              type_str == "word-translate-text" ? true : false
            }
            using_african_word={this.props.using_african_word}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.answerCorrect}
          />
        </div>
      );
    } else if (
      type_str == "match-sound-picture" ||
      type_str == "word-sound-picture"
    ) {
      return (
        <div>
          <Questionfour
            questionObject={question}
            currentQuestion={this.state.currentQuestion}
            incQN={this.incrementQuestionNumber}
            incS={this.incrementScore}
            setSelectedChoice={this.setSelectedChoice}
            setCurrentQuestionObject={this.setCurrentQuestionObject}
            currentQuestionObject={this.state.currentQuestionObject}
            answerSubmitted={this.state.answerSubmitted}
            auto_generated_choices={
              type_str == "word-sound-picture" ? true : false
            }
            using_african_word={this.props.using_african_word}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.answerCorrect}
          />
        </div>
      );
    } else if (type_str == "teaching-translation") {
      return <Teachtranslation questionObject={question} />;
    } else if (type_str == "word-bank-twi-to-english") {
      return (
        <div>
          <WordBank
            questionObject={question}
            answerSubmitted={this.state.answerSubmitted}
            setClick={(click) => (this.childCheckAnswer = click)}
            answerCorrect={this.state.submitted_answer_correct}
            questionPhrase={"underlying"}
            hintWords={"literal"}
            answerPhrase={"understood"}
            answerExtras={"english_extras"}
            displayPhrase={true}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.submitted_answer_correct}
          />
        </div>
      );
    } else if (type_str == "word-bank-sound-twi-to-twi") {
      return (
        <div>
          <WordBank
            questionObject={question}
            answerSubmitted={this.state.answerSubmitted}
            setClick={(click) => (this.childCheckAnswer = click)}
            answerCorrect={this.state.submitted_answer_correct}
            questionPhrase={"underlying"}
            hintWords={"literal"}
            answerPhrase={"underlying"}
            answerExtras={"twi_extras"}
            displayPhrase={false}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.submitted_answer_correct}
          />
        </div>
      );
    } else if (type_str == "word-bank-sound-twi-to-english") {
      return (
        <div>
          <WordBank
            questionObject={question}
            answerSubmitted={this.state.answerSubmitted}
            setClick={(click) => (this.childCheckAnswer = click)}
            answerCorrect={this.state.submitted_answer_correct}
            questionPhrase={"underlying"}
            hintWords={"literal"}
            answerPhrase={"understood"}
            answerExtras={"english_extras"}
            displayPhrase={false}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.submitted_answer_correct}
          />
        </div>
      );
    } else if (type_str == "word-bank-english-to-twi") {
      return (
        <div>
          <WordBank
            questionObject={question}
            answerSubmitted={this.state.answerSubmitted}
            setClick={(click) => (this.childCheckAnswer = click)}
            answerCorrect={this.state.submitted_answer_correct}
            questionPhrase={"understood"}
            hintWords={"underlying"}
            answerPhrase={"underlying"}
            answerExtras={"twi_extras"}
            displayPhrase={true}
          />
          <CheckmarksThree
            answerSubmitted={this.state.answerSubmitted}
            answerCorrect={this.state.submitted_answer_correct}
          />
        </div>
      );
    } else if (type_str == "color-coded-phrases") {
      return <ColorCodedTranslation questionObject={question} />;
    }
  }

  aggregateMissedWord(
    str_has_word,
    str_has_phrase,
    str_word,
    str_phrase,
    str_question_type
  ) {
    this.state.missedWords["word_question_type_map"].push({
      has_word: str_has_word,
      has_phrase: str_has_phrase,
      word: str_word,
      phrase: str_phrase,
      question_type: str_question_type,
    });
  }
  // handleCompleteQuiz(e, input_user, jump_link) {
  //   // Removing prevent default to allow anchor tag link referencing.
  //   // may affect ajax call.
  //   // e.preventDefault();
  //   var data = this.computeLessonLogData();
  //   this.lessonLog(data)
  //     .then((response) => {
  //       this.props.history.push(jump_link);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }
  // lessonLog(data) {
  //   var path = "lesson_log/";
  //   return this.state.apivalue.postUsingToken(path, data);
  // }
  favedCards = async (data) => {
    let path = "played_cards/";
    return this.state.apivalue.postUsingToken(path, data);
    // [{'card_id': 3, 'faved': True}, {'card_id': 2, 'faved': True}, {'card_id': 7, 'faved': True}, {'card_id': 6, 'faved': True}, {'card_id': 1, 'faved': True}, {'card_id': 5, 'faved': True}]
  };
  sendPlayedCards = async () => {
    let data = this.state.card_faved_map;
    // console.log("favegame: SEND card faved map: ", this.state.card_faved_map);
    // console.log("favegame: SEND card faved map length: ", data.length);
    if (data.length > 0) {
      let response = await this.favedCards(data);
      return response;
    }
  };
  aggregatePlayedCards = (card_id, faved) => {
    this.state.card_faved_map.push({
      card_id: card_id,
      faved: faved,
    });
    // console.log("favegame: card faved map: ", this.state.card_faved_map);
  };
  sendMissedWords() {
    // e.preventDefault();
    this.aggregateMissedWord("true", "false", "yes", "", "match-word-picture");
    this.aggregateMissedWord("true", "false", "no", "", "match-word-picture");
    this.aggregateMissedWord("true", "false", "yes", "", "match-sound-picture");
    this.aggregateMissedWord("true", "false", "no", "", "match-sound-picture");

    var data = this.state.missedWords;
    var baseURL = "https://dialogue-rest-api.herokuapp.com/";
    if (this.props.contextapivalue.devMode) baseURL = "http://localhost:8000/";
    var lesson_num = this.state.lesson_number;
    var path = "missed_words/" + lesson_num + "/";
    var requesturl = baseURL + path;

    fetch(requesturl, {
      method: "POST",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            this.setState({ questionsauto: json });
            this.setState({ totalQuestions: Object.keys(json).length });
          });
        } else {
          //Need to display some informative message.
          console.error(
            "Failed to send records. Session may have timed out. Try logging in again."
          );
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  handleCompleteQuiz(e, input_user, jump_link) {
    // Removing prevent default to allow anchor tag link referencing.
    // may affect ajax call.
    // e.preventDefault();
    // console.log("langs: mcg jump-link: ", jump_link);
    this.sendPlayedCards().catch(alert);
    var data = this.computeLessonLogData();
    let mypath = this.props.my_path;
    let jump_hash = `#${mypath.slice(1, mypath.length)}`;

    // console.log("langs: jump_hash: ", jump_hash);
    this.lessonLog(data)
      .then((response) => {
        this.props.history.push(
          // jump_link
          {
            pathname: jump_link,
            state: { language: this.state.language },
            hash: jump_hash,
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  lessonLog(data) {
    let value = this.state.apivalue;
    var path = `lesson_log/?v=${value.apiVersion}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    };
    return value.postUsingCustomHeaders(path, data, headers);
  }
  computeLessonLogData() {
    var quizScore = 0;
    var graded_questions = 0;
    var percent_score = 0;
    if (this.state.reviewedMissedWords == true) {
      //second time around
      quizScore = this.state.initialQuizScore;
      //Change to initialQuestionToIgnore when missedWords starts
      //using teach-translation
      graded_questions =
        this.state.initialQuizTotalQuestion - this.state.questionsToIgnore;
    } else {
      //first time around
      //Send quiz score to backend as initialQuizScore
      quizScore = this.state.score;
      this.setState({ initialQuizScore: quizScore }, () => {
        // console.log(
        //   "initialQuizScore finally set: ",
        //   this.state.initialQuizScore
        // );
      });
      graded_questions =
        this.state.totalQuestions - this.state.questionsToIgnore;
    }
    percent_score = 100 * (quizScore / graded_questions);
    var data = {
      lessonid: this.state.lesson_number,
      language_name: this.state.language,
      graded_percent: percent_score,
    };
    return data;
  }
  // {/*<div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>*/}
  render() {
    var loadera = loader2;
    var mypath = this.props.my_path;
    var select_course_link = "/select_course";
    if (this.props.select_course_link != null) {
      select_course_link = this.props.select_course_link;
    } else {
      select_course_link = this.state.select_course_link;
    }
    // console.log("langs: state scl: ", this.state.select_course_link);
    var select_course_jump_link = `${select_course_link}`;
    // #${mypath.slice(
    //   1,
    //   mypath.length
    // )}`;
    const language = this.state.language;
    var language_logo = dialogue_africa_logo;
    if (language == "twi" || language == null) {
      language_logo = dialogue_twi_logo;
    } else if (language == "yoruba") {
      language_logo = dialogue_yoruba_logo;
    } else if (language == "igbo") {
      language_logo = dialogue_igbo_logo;
    } else if (language == "ga") {
      language_logo = dialogue_ga_logo;
    } else if (language == "swahili") {
      language_logo = dialogue_swahili_logo;
    }
    return (
      <UserConsumer>
        {(value) => (
          <div className="Multiplechoice">
            {/* NAV BAR */}
            <head>
              <meta charset="utf-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta
                name="description"
                content="Dialogue-Africa is an online platform for learning African languages including Twi, Fante, Ga, Hausa, Yoruba and More "
              />
              <title>
                {" "}
                Dialogue-Africa: Learn African Languages like Twi, Fante, Ga,
                Igbo, Hausa, Yoruba, Shona, Amharic and More{" "}
              </title>
              <link
                rel="stylesheet"
                href="./styleshannon1.css"
                type="text/css"
              />
            </head>
            <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
              <Link
                class="navbar-brand"
                to={{
                  pathname: this.state.select_course_link,
                  state: { language: this.state.language },
                }}
              >
                <img
                  class="favicon dialoguelogobar"
                  alt="Brand"
                  src={language_logo}
                  title="Select Course Page"
                />
              </Link>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item active">
                    {value.isGuest ? (
                      <a
                        class="fontB fontregular btn btn-success my-2 my-sm-0"
                        href="/create_profile"
                      >
                        <span class="fa fa-user-plus"></span> Create Profile{" "}
                        <span class="sr-only">(current)</span>
                      </a>
                    ) : (
                      <a
                        class="fontB fontregular btn btn-outline-success my-2 my-sm-0"
                        href="#"
                      >
                        <span class="fa fa-user-circle"></span> Hi{" "}
                        {value.isLoggedIn
                          ? value.get_first_name_or_username()
                          : "Guest"}{" "}
                        <span class="sr-only">(current)</span>
                      </a>
                    )}
                  </li>
                  <li class="nav-item active">
                    <a class="fontB fontregular nav-link" href="blog-main.html">
                      <span class="fa fa-book"></span> Blog{" "}
                      <span class="sr-only"> (current) </span>{" "}
                    </a>
                  </li>
                  <li class="nav-item active">
                    <a
                      class="fontB fontregular btn btn-outline-info my-2 my-sm-0"
                      href={
                        "https://brown.co1.qualtrics.com/jfe/form/SV_cOMTf4CUsSf5iyp"
                      }
                    >
                      <span class="fa fa-comment"></span> Talk to us!{" "}
                      <span class="sr-only"> (current) </span>{" "}
                    </a>
                  </li>
                  {/* <li class="nav-item active">
                      <Link
                        class="nav-link "
                        to={
                          this.props.select_course_link != null
                            ? this.props.select_course_link
                            : "/select_course"
                        }
                      >
                        <span class="fa fa-th-list"></span> Select Course
                      </Link>
                    </li> */}
                  <li class="nav-item active">
                    <a
                      class="fontB fontregular nav-link"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            "You can save your guest progress first. Still want to logout?"
                          )
                        )
                          value.logout();
                      }}
                      href="javascript:void(0);"
                    >
                      <span class="fa fa-sign-out"></span> Logout
                      <span class="sr-only">(current)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <br />
            <br />
            <br />

            <div>
              {this._ismounted ? (
                <div>
                  <div>
                    <Progress
                      category={
                        this.state.currentQuestionObject.question_category
                      }
                      lessonFetched={this.state.lessonDataFetchComplete}
                      currentThemeColor={this.state.currentThemeColor}
                      themeColors={this.themeColors}
                      totalQuestions={this.state.totalQuestions}
                      currentQuestion={this.state.currentQuestion}
                      score={this.state.score}
                      submittedOrSkippedAnswersCount={
                        this.state.submittedOrSkippedAnswersCount
                      }
                      isReview={this.state.reviewedMissedWords}
                      questionsToIgnore={this.state.questionsToIgnore}
                      stars={this.calculateStars()}
                      culture_card={this.state.culture_card}
                    />
                  </div>
                  {/* QUIZ Content */}
                  <div class="quizcontent">
                    <br />
                    <br />
                    <div>
                      <div class="questionchoices">
                        {this.state.questionsauto.map((question) => {
                          if (
                            this.state.currentQuestion ===
                            question.question_number
                          ) {
                            return (
                              <div>
                                {" "}
                                {this.renderQuestion(
                                  question.question_type,
                                  question
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>

                    <div>
                      {/* DURING QUIZ CONTINUE/SUBMIT DISPLAY */}
                      {this.state.currentQuestion <=
                      this.state.totalQuestions ? (
                        <div>
                          {this.state.answerSubmitted ? (
                            <div>
                              {this.state.checkmark === "wrong" ? (
                                <div>
                                  {this.state.currentQuestion ==
                                  this.state.totalQuestions ? (
                                    <button
                                      class={this.getNextButtonStyle()}
                                      onClick={this.nextQuestion}
                                    >
                                      {" "}
                                      Continue{" "}
                                    </button>
                                  ) : (
                                    <button
                                      class={this.getNextButtonStyle()}
                                      onClick={this.nextQuestion}
                                    >
                                      Next Question
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          ) : (
                            <div>{this.skipButtonDisplay()}</div>
                          )}
                          {this.showSubmitButton() &&
                          this.state.answerSubmitted === false ? (
                            /* Deliberately using onClick={ () => this.submitAnswer} to make sure
                                    the child quiz component is mounted and the function is assigned
                                    by the time the button is clicked
                                */
                            <div>{this.submitButtonDisplay()}</div>
                          ) : (
                            // <button
                            //   class="buttonstyle1 buttongreen"
                            //   onClick={() => this.submitAnswer(this)}
                            // >
                            //   Submit My Answer!{" "}
                            // </button>
                            <div></div>
                          )}
                        </div>
                      ) : (
                        // END OF QUIZ CONTINUE/SUBMIT DISPLAY
                        <div>
                          <Confetti
                            numberOfPieces={60}
                            width={window.innerWidth}
                            height={window.innerHeight}
                          />

                          <div>
                            <div className="congrats_checkmarkitems">
                              {this.calculateStars() > 0 ? (
                                <img src={goldstar} />
                              ) : (
                                <img src={darkstar} />
                              )}
                              {this.calculateStars() > 1 ? (
                                <img src={goldstar} />
                              ) : (
                                <img src={darkstar} />
                              )}
                              {this.calculateStars() > 2 ? (
                                <img src={goldstar} />
                              ) : (
                                <img src={darkstar} />
                              )}
                              {this.calculateStars() > 3 ? (
                                <img src={goldstar} />
                              ) : (
                                <img src={darkstar} />
                              )}
                            </div>

                            {/*
                                  <img id = "congrats_image" src={congrats_image}/>
                                  */}
                            <div className="congrats_page_arrange">
                              {/*<img src = {professions_image}></img>   onClick={this.quizMissedWords} */}
                            </div>
                          </div>
                          <div>
                            <div>
                              {this.state.stars < 3 ? (
                                <div>
                                  <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#exampleProverb"
                                    onClick={this.restartAll}
                                    class="buttonstyle1 buttonblue"
                                  >
                                    <i class="fas fa-redo"></i>{" "}
                                    {this.retryButtonDisplay()}
                                  </button>
                                  <button
                                    onClick={(e) =>
                                      this.handleCompleteQuiz(
                                        e,
                                        value.user,
                                        select_course_jump_link
                                      )
                                    }
                                    class="buttonstyle1 buttongreen"
                                  >
                                    {" "}
                                    Continue{" "}
                                  </button>
                                </div>
                              ) : (
                                this.returnProverbModalButton(
                                  value,
                                  select_course_jump_link
                                )
                              )}
                            </div>
                          </div>
                          <SocialShare />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                // PAGE LOADING SCREEN
                <div>
                  <img id="loader" src={loadera} />
                </div>
              )}
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}

export default MultiplechoiceGeneral;
