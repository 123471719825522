import React, { Component } from "react";
import TwiSkillTree from "./TwiSkillTree";
import YorubaSkillTree from "./YorubaSkillTree";
import IgboSkillTree from "./IgboSkillTree";
import GaSkillTree from "./GaSkillTree";
import MooreSkillTree from "./MooreSkillTree";
import SwahiliSkillTree from "./SwahiliSkillTree";
import { Link, Switch } from "react-router-dom";
import ScrollableAnchor from "react-scrollable-anchor";
import { configureAnchors } from "react-scrollable-anchor";
import { UserConsumer } from "./components/User/User.js";
import correctmark from "./components/app_content/navigation_pages_content/images/1x/completionStar.png";
import cancel2 from "./components/app_content/navigation_pages_content/images/cancel2.png";
import blank from "./components/app_content/navigation_pages_content/images/blank2.png";
import {
  Popup,
  Rating,
  Image,
  Button,
  Modal,
  Form,
  Input,
} from "semantic-ui-react";
import dialogue_africa_logo from "./Images/da-logo-lineart.png";
import dialogue_twi_logo from "./Images/dialogue-africa-ghana-twi-logo.png";
import dialogue_yoruba_logo from "./Images/dialogue-africa-nigeria-yoruba-logo.png";
import dialogue_ga_logo from "./Images/dialogue-africa-ghana-ga-logo.png";
import dialogue_igbo_logo from "./Images/dialogue-africa-nigeria-igbo-logo.png";
import dialogue_swahili_logo from "./Images/dialogue-africa-learn-swahili-tanzania-kenya-logo.png";
import "./Selectcourse.css";

// Offset all anchors by -60 to account for a fixed header
// and scroll more quickly than the default 400ms
configureAnchors({
  offset: -60,
  scrollDuration: 100,
  keepLastAnchorHash: true,
});

const PopupExampleHtml = ({
  component: triggerComponent,
  rating: actual,
  maxRating: max,
}) => (
  <Popup trigger={triggerComponent}>
    <Popup.Header>grade points</Popup.Header>
    <Popup.Content>
      <Rating icon="star" defaultRating={actual} maxRating={max} />
      <p class="fontRegular fontB">
        Earn 3+ for a proverb point{" "}
        <i id="nicegreen" class="fas fa-seedling"></i>
      </p>
    </Popup.Content>
  </Popup>
);
const PopupExampleReaccess = ({ component: triggerComponent }) => (
  <Popup trigger={triggerComponent}>
    <Popup.Header>
      Subscribe <i id="metallicgold" class="fas fa-crown"></i>
    </Popup.Header>
    <Popup.Content>
      <p>Renew quiz access & continue where you left off.</p>
    </Popup.Content>
  </Popup>
);
const PopupExampleHtmlLocked = ({ component: triggerComponent }) => (
  <Popup trigger={triggerComponent}>
    <Popup.Header>
      Locked <i id="metallicgold" class="fas fa-lock"></i>
    </Popup.Header>
    <Popup.Content>
      <p>Complete previous quizzes to unlock!</p>
    </Popup.Content>
  </Popup>
);
const PopupExampleHtmlDeveloping = ({ component: triggerComponent }) => (
  <Popup trigger={triggerComponent}>
    <Popup.Header>
      {" "}
      On the way <span> </span>
      <i id="aluminumgrey" class="fas fa-hammer">
        {" "}
      </i>
      <i id="aluminumgrey" class="fas fa-tools">
        {" "}
      </i>
    </Popup.Header>
    <Popup.Content>
      <p> Building new material. Stay tuned!</p>
    </Popup.Content>
  </Popup>
);
// TODO: destructure in args right away ({isOpen, ....})
// and do away with the props
const RenderFeedbackModal = (props) => (
  <Modal
    closeIcon={{
      style: { top: "1.0535rem", right: "1rem" },
      color: "grey",
      name: "close",
    }}
    style={{ top: "30%", background: "none" }}
    trigger={<Button>Show Modal</Button>}
    open={props.isOpen}
    centered={false}
    size={"small"}
    onClose={props.closeFunc}
    closeOnDimmerClick={false}
  >
    <div id="feedbackModalHeader" class="header feedbackModalInfo">
      Your Voice Matters to Us!
    </div>
    <Modal.Content image>
      <Image
        wrapped
        size="medium"
        src="https://www.dialogue-africa.com/Images/blog-how-to-say-i-love-you-in-twi.png"
      />
      <Modal.Description>
        <div className="feedbackParent feedbackModalInfo">
          <div>
            <div id="feedbackModalSubHeader">
              {" "}
              How do you feel about Dialogue-Africa?
            </div>

            <button
              onClick={() => props.onClickFunc(props.onClickFuncArg)}
              id="feedbackModalButton"
              class="ui positive button"
            >
              Continue
            </button>
          </div>
        </div>
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
const RenderProfileUpdateModal = ({ ctx, isOpen, closeFunc }) => (
  <div class="rpu">
    <div></div>
    <Modal
      closeIcon={{
        style: { top: "1.0535rem", right: "1rem" },
        color: "grey",
        name: "close",
      }}
      style={{ top: "150px", background: "none" }}
      trigger={<Button>Show Modal</Button>}
      open={isOpen}
      centered={false}
      size={"small"}
      onClose={closeFunc}
      closeOnDimmerClick={false}
    >
      <div id="feedbackModalHeader" class="header feedbackModalInfo">
        {ctx.state.profileUpdated ? "Updated!" : "Update Your Info!"}
      </div>
      <Modal.Content image>
        <Image
          wrapped
          size="small"
          src="https://s3.amazonaws.com/aws-website-dialogue-africanlanguagelearning-f6gw4/Professions+Images/twer3.png"
          rounded
        />
        <Modal.Description>
          <div>
            <div class="profile_input fontB">
              <label for="fname">First name:</label>
              <input
                type="text"
                id="fname"
                name="fname"
                onChange={ctx.handleChange}
                placeholder={ctx.state.profileModalInfo.first_name}
              />
              <br />
              <label id="llabel" for="lname">
                Last name:
              </label>
              <input
                type="text"
                id="lname"
                name="lname"
                onChange={ctx.handleChange}
                placeholder={ctx.state.profileModalInfo.last_name}
              />
              <br />
              <label id="elabel" for="email_input">
                E-mail:
              </label>
              <input
                type="text"
                id="email_input"
                name="email_input"
                onChange={ctx.handleChange}
                placeholder={ctx.state.profileModalInfo.email}
              />
              <br />
            </div>

            <button
              onClick={() => ctx.updateProfileModalInfo()}
              class="ui positive button"
              id="profile_update_btn"
            >
              Update
            </button>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  </div>
);
class Selectcourse extends Component {
  constructor(props) {
    super(props);
    this.getCompletionStats = this.getCompletionStats.bind(this);
    this.applytint = this.applytint.bind(this);
    this.returnQuiz = this.returnQuiz.bind(this);
    this.rating = this.rating.bind(this);
    this.quizAvailable = this.quizAvailable.bind(this);
    this.logout_routine = this.logout_routine.bind(this);
    this.getProverbPointCount = this.getProverbPointCount.bind(this);
    this.feedbackAlert = this.feedbackAlert.bind(this);
    this.closeFeedbackAlert = this.closeFeedbackAlert.bind(this);
    this.returnSkillTreeByLanguage = this.returnSkillTreeByLanguage.bind(this);
    this.state = {
      user_profile_info: {},
      savable_progress: false,
      closeFeedbackModal: false,
      language: this.getLanguage(), // creates ULPD in backend for quizzes
      profileModalIsOpen: false,
      profileUpdated: false,
      profileModalInfo: {
        first_name: "",
        last_name: "",
        email: "",
      },
      fn: "",
      ln: "",
      em: "",
      skillTrees: {
        twi: TwiSkillTree,
        yoruba: YorubaSkillTree,
        igbo: IgboSkillTree,
        swahili: SwahiliSkillTree,
        ga: GaSkillTree,
        moore: MooreSkillTree,
      },
      // Track users who have unlocked or completed all available quizzes
      all_unlocked: false,
      all_complete: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchProfileInfo();
    let value = this.props.contextapivalue;
    value.user_profile_details();
  }
  getLanguage = () => {
    // if null then twi. if not null and not "general" then this.props.language
    // if not null and general then check for location state and pick lang variable
    // if no location state even when "general"(no real case), then go with null.
    // console.log("langs: sco prop: ", this.props);
    // console.log("langs: sco lang ", this.props.language);
    // console.log("langs: sco loc", this.props.location);
    // console.log("langs: sco ls", this.props.location.state);
    if (this.props.match && this.props.match.params) {
      // console.log("langs: sco mcp", this.props.match.params.culture);
    }

    if (this.props.language == null) {
      return "twi";
    } else if (this.props.language == "general") {
      if (this.props.match && this.props.match.params) {
        // console.log("langs: sco mcp", this.props.match.params.culture);
        return this.props.match.params.culture;
      }
      // if (this.props.location.state) {
      //   return this.props.location.state.language;
      // }
      return null;
    } else {
      return this.props.language;
    }
  };
  // export function lessonProgress(language){ // one line call to api
  //  api("lesson_progress_details",language)
  // }
  handleChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    // console.log("nn name: ", n);
    if (name == "fname" && val != "") {
      this.setState({ fn: val });
    } else if (name == "lname" && val != "") {
      this.setState({ ln: val });
    } else if (name == "email_input" && val != "") {
      this.setState({ em: val });
    }
  };

  validateEmail = (em) => {
    return em;
  };
  validateName = (name) => {
    return name;
  };
  sendProfileUpdates = () => {};
  checkForProfileInfoUpdates = (fn, ln, em) => {
    let samef = fn == this.state.profileModalInfo.first_name;
    let samel = ln == this.state.profileModalInfo.last_name;
    let same_em = em == this.state.profileModalInfo.email;
    return samef && samel && same_em;
  };
  reset_fn_ln_em = () => {
    this.setState({
      fn: this.state.profileModalInfo.first_name,
      ln: this.state.profileModalInfo.last_name,
      em: this.state.profileModalInfo.email,
    });
  };
  updateProfileModalInfo = () => {
    // check if profile info updates were made
    let fn = this.state.fn;
    let ln = this.state.ln;
    let em = this.state.em;
    let no_changes_made = this.checkForProfileInfoUpdates(fn, ln, em);
    if (no_changes_made) {
      return;
    }

    // validate changes
    const first_name = this.validateName(fn);
    const last_name = this.validateName(ln);
    const email = this.validateEmail(em);

    // save the changes
    let new_info = {
      first_name: first_name,
      last_name: last_name,
      email: email,
    };
    this.setState({ profileModalInfo: new_info, profileUpdated: true });
    this.reset_fn_ln_em();
  };
  saveProfileModalInfo = () => {
    this.sendProfileUpdates();
    this.setState({ profileModalIsOpen: false });
  };

  openProfileModal = () => {
    this.reset_fn_ln_em();
    this.setState({ profileModalIsOpen: true });
  };
  closeProfileModal = () => {
    this.setState({ profileModalIsOpen: false, profileUpdated: false });
    this.reset_fn_ln_em();
  };
  displayUpdateProfileModal = () => {
    return (
      <div>
        {this.state.profileModalIsOpen ? (
          <RenderProfileUpdateModal
            isOpen={true}
            ctx={this}
            closeFunc={this.closeProfileModal}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  lessonProgress(language) {
    let value = this.props.contextapivalue;
    const path = `lesson_progress_details/${language}/?v=${value.apiVersion}`;
    return value.getUsingToken(path);
  }

  getCompletionStats(tree_quizzes_set) {
    return this.lessonProgress(this.state.language)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        return response.json().then((cstats) => {
          if (cstats) var keyslen = Object.keys(cstats).length;
          if (keyslen > 0) {
            this.setState({ savable_progress: true });
          }
          var loadedLessonStats = [];
          let found_unfinished = false;
          let found_locked = false;
          for (var i = 0; i < keyslen; i++) {
            var lesson_name = cstats[i].lesson_name;
            // checking lesson name exists in
            // language skill tree state
            if (tree_quizzes_set.has(lesson_name)) {
              let stats = cstats[i];
              loadedLessonStats[lesson_name] = stats;

              // Add developing vs locked logic
              if (stats == null || !this.quizAvailable(stats)) {
                found_locked = true;
                found_unfinished = true;
              } else {
                // check completed for unlocked
                let stars = this.rating(stats.max_graded_percent);
                let starAchieved = stats && stats.unlocked && stars > 2;
                if (!starAchieved) {
                  found_unfinished = true;
                }
              }
            }
          }
          // set unlocked if 1. didn't find locked quiz in playable input set & 2. user
          // has unlocked as many quizzes as there are in playable input set
          let au = found_locked == false && keyslen >= tree_quizzes_set.size;
          let ac = !found_unfinished;
          this.setState({ all_unlocked: au });
          this.setState({ all_complete: ac });
          return loadedLessonStats;
        });
      })
      .catch((error) => {
        console.log(error);
        alert("User session expired. Please log-in.");
        let value = this.props.contextapivalue;
        value.reset_state_and_local_storage();
        this.props.history.push("/login");
      });
  }
  // api call
  profileInfo(language) {
    let platform_name = "web";
    let value = this.props.contextapivalue;
    let path = `select_course_profile_info/${language}/${platform_name}/`;
    return value.getUsingToken(path);
  }

  fetchProfileInfo() {
    this.profileInfo(this.state.language).then((res) => {
      if (res.ok) {
        res.json().then((profile_info) => {
          this.setState({ user_profile_info: profile_info });
        });
      } else {
        console.error("Error accessing user profile info. Try again");
      }
    });
  }

  feedbackAlert(redirectBool) {
    const feedback_form_link =
      "https://brown.co1.qualtrics.com/jfe/form/SV_cOMTf4CUsSf5iyp";
    let value = this.props.contextapivalue;
    let path = "alerted_for_feedback/";
    value.postUsingToken(path, {}).then((res) => {
      if (!res.ok) {
        console.error("Error saving feedback alert.");
      } else {
        if (redirectBool) {
          window.location = feedback_form_link;
        }
      }
    });
  }

  closeFeedbackAlert() {
    this.setState({ closeFeedbackModal: true });
    this.feedbackAlert(false);
  }

  getProverbPointCount() {
    return this.state.user_profile_info.proverb_point_count;
  }

  applytint(isGuest) {
    if (isGuest === true) {
      return "greyout";
    } else {
      return "";
    }
  }
  applydim = (apply) => {
    let dimStyle = "";
    if (apply == true) {
      dimStyle = "dimmedout";
    }
    return dimStyle;
  };
  applyHeaderColor = () => {
    // updated to simply use one highlight color
    // since leading premium quizzes leading to paywall
    // have fixed color
    let colorStyle = "linktextAvailable";
    return colorStyle;
  };

  logout_routine() {
    localStorage.removeItem("token");
    localStorage.setItem("username", "guest");
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("gueststatus", true);
  }

  rating(percent) {
    // console.log("rating %: ", percent);
    if (percent > 95) return 4;
    if (percent > 70) return 3;
    if (percent > 50) return 2;
    if (percent > 25) {
      return 1;
    } else {
      return 0;
    }
  }

  quizAvailable(stats) {
    if (stats.unlocked) {
      return true;
    }
    return false;
  }

  check_subscription = async (needsBarrier, linkstring) => {
    // Handles case where
    // Fetch access expiration info
    let value = this.props.contextapivalue;
    let access_expired = await value.fetch_premium_expiry();
    const proceedingToQuiz = !needsBarrier;
    // Refresh if backend access expiration hasn't yet
    // registered in frontend context api storage.
    if (access_expired && proceedingToQuiz && value.hasPremiumAccess) {
      this.props.history.go(0);
    } else {
      this.props.history.push({
        pathname: linkstring,
        state: { language: this.state.language },
      });
    }
  };
  lockedQuiz = (image, Header) => {
    return (
      <div>
        <img class="check_complete" src={blank}></img>
        <div style={{ textDecoration: "none" }} id="linktext">
          <PopupExampleHtmlLocked
            component={
              <img
                id="courseimage"
                class={this.applytint(true)}
                src={image}
              ></img>
            }
          />
          <h3 class="fontB fontregular" id="lessonnameheader">
            {Header}
          </h3>
        </div>
      </div>
    );
  };
  developingQuiz = (image, Header) => {
    return (
      <div>
        <img class="check_complete" src={blank}></img>
        <div style={{ textDecoration: "none" }} id="linktext">
          <PopupExampleHtmlDeveloping
            component={
              <img
                id="courseimage"
                class={this.applytint(true)}
                src={image}
              ></img>
            }
            // rating={stars}
            // maxRating={4}
          />
          <h3 id="lessonnameheader">{Header}</h3>
        </div>
      </div>
    );
  };
  skillTreeUnavailableQuizzes = (image, Header, value) => {
    let no_prem = !value.hasPremiumAccess;

    // Return developing if a premium user has unlocked all available quizzes
    if (!no_prem && this.state.all_unlocked) {
      return this.developingQuiz(image, Header);
    }

    // Return developing if a free user has completed all available quizzes
    if (no_prem && this.state.all_complete) {
      return this.developingQuiz(image, Header);
    }

    return this.lockedQuiz(image, Header);
  };
  getColoredQuizHeader = (limited) => {
    // different color header for free users to recognize
    // leading premium quizzes.
    let value = this.props.contextapivalue;
    if (limited == true && !value.hasPremiumAccess) {
      return "lessonheaderPremium";
    }
    return "lessonheaderAvailable";
  };
  availableQuiz = (image, Header, stars, limited) => {
    return (
      <div>
        <PopupExampleHtml
          component={<img id="courseimage" src={image}></img>}
          rating={stars}
          maxRating={4}
        />

        <h3 id={this.getColoredQuizHeader(limited)}>{Header}</h3>
      </div>
    );
  };
  resubscribableQuiz = (image, Header, stars) => {
    return (
      <div>
        <PopupExampleReaccess
          component={
            <img id="courseimage" class={this.applydim(true)} src={image}></img>
          }
        />
        <h3 id="lessonnameheader">{Header}</h3>
      </div>
    );
  };
  starDisplay = (starAchieved) => {
    if (starAchieved) {
      return (
        <img id="starAchieved" class="check_complete" src={correctmark}></img>
      );
    } else {
      return <img class="check_complete" src={blank}></img>;
    }
  };
  returnQuiz(linkstring, image, Header, quiz_info_spread) {
    const tree_has_quiz = quiz_info_spread[1];
    var stats = quiz_info_spread[2];
    // quizLimited more used in Root.js to manually determine limited quizzes
    const quizLimited = quiz_info_spread[3];
    let value = this.props.contextapivalue;
    if (!tree_has_quiz) {
      return this.skillTreeUnavailableQuizzes(image, Header, value);
    }

    // for unlocked and limited quizzes, if user has premium access grant. if not, place barrier.
    let unlocked = stats != null && this.quizAvailable(stats);
    let resubscribableQuiz =
      unlocked && quizLimited && this.state.language === "twi";
    let subscribedBefore = this.state.user_profile_info.subscribed_before;
    let needsReaccessBarrier =
      !value.hasPremiumAccess && resubscribableQuiz && subscribedBefore;
    // Locked or stats info unavailable
    if (stats == null || !this.quizAvailable(stats)) {
      return this.lockedQuiz(image, Header);
    }

    // Quiz Available
    var stars = this.rating(stats.max_graded_percent);
    var starAchieved = stats.unlocked && stars > 2;
    var refstring = "#" + linkstring;
    return (
      <div>
        <a href={refstring}></a>
        <ScrollableAnchor id={linkstring}>
          <div
            style={{ textDecoration: "none" }}
            id={this.applyHeaderColor()}
            onClick={() => {
              this.check_subscription(needsReaccessBarrier, linkstring);
            }}
          >
            {this.starDisplay(starAchieved)}
            {needsReaccessBarrier
              ? this.resubscribableQuiz(image, Header, stars)
              : this.availableQuiz(image, Header, stars, quizLimited)}
          </div>
        </ScrollableAnchor>
      </div>
    );
  }

  returnSkillTreeByLanguage() {
    let LanguageSkillTree = TwiSkillTree;
    // if (this.state.language == "twi") {
    //   return (
    //     <TwiSkillTree
    //       returnQuiz={this.returnQuiz}
    //       fetchProfileInfo={this.fetchProfileInfo}
    //       getCompletionStats={this.getCompletionStats}
    //     />
    //   );
    // }
    if (this.state.language in this.state.skillTrees) {
      LanguageSkillTree = this.state.skillTrees[this.state.language];
    } else {
      LanguageSkillTree = MooreSkillTree;
    }

    return (
      <LanguageSkillTree
        returnQuiz={this.returnQuiz}
        fetchProfileInfo={this.fetchProfileInfo}
        getCompletionStats={this.getCompletionStats}
        language={this.state.language}
      />
    );
  }

  render() {
    const language = this.state.language;
    var language_logo = dialogue_africa_logo;
    if (language == "twi") {
      language_logo = dialogue_twi_logo;
    } else if (language == "yoruba") {
      language_logo = dialogue_yoruba_logo;
    } else if (language == "ga") {
      language_logo = dialogue_ga_logo;
    } else if (language == "igbo") {
      language_logo = dialogue_igbo_logo;
    } else if (language == "swahili") {
      language_logo = dialogue_swahili_logo;
    }
    return (
      <Switch>
        <UserConsumer>
          {(value) => (
            <div>
              <head>
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta
                  name="viewport"
                  content="width=device-width,initial-scale=1,shrink-to-fit=no"
                />
                <meta
                  name="description"
                  content="Dialogue-Africa is an online platform for learning African languages including Twi, Fante, Ga, Hausa, Yoruba and More "
                />
                <title>
                  {" "}
                  Dialogue - Africa: Learn African Languages like Twi, Fante,
                  Ga, Igbo, Hausa, Yoruba, Shona, Amharic and More{" "}
                </title>
                <link
                  rel="stylesheet"
                  href="./styleshannon1.css"
                  type="text/css"
                />
              </head>
              <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                {/* <Link class="navbar-brand" to="#" style={{ cursor: "default" }}> */}
                <div>
                  <img
                    id="languagelogo"
                    alt="Brand"
                    src={language_logo}
                    usemap="#image-map-1"
                  />
                  <map name="image-map-1">
                    <div style={{ cursor: "pointer" }}>
                      <area
                        target="_parent"
                        alt="dialogue africa"
                        title="Dialogue-Africa Home Page"
                        onClick={(e) => this.props.history.push("/")}
                        coords="3,75,240,0"
                        shape="rect"
                      />
                    </div>
                  </map>
                </div>
                {/* </Link> */}

                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                  <ul class="fontB navbar-nav ml-auto">
                    <li class="fontB nav-item active">
                      <p id="provcount">
                        <i id="seedlinglarge" class="fas fa-seedling"></i>x{" "}
                        {this.getProverbPointCount()}{" "}
                      </p>{" "}
                      <span class="sr-only">(current)</span>
                    </li>
                    <li class="fontB fontregular nav-item active">
                      {value.isGuest ? (
                        this.state.savable_progress ? (
                          <Link
                            class="fontB fontregular btn btn-danger my-2 my-sm-0"
                            to={{
                              pathname: "/create_profile",
                              state: {
                                language: this.state.language,
                              },
                            }}
                          >
                            <span class="fa fa-user-plus"></span> Save Your
                            Progress <span class="sr-only">(current)</span>
                          </Link>
                        ) : (
                          <Link
                            class="fontB fontregular btn btn-success my-2 my-sm-0"
                            to="/create_profile"
                          >
                            <span class="fa fa-user-plus"></span> Welcome{" "}
                            {value.isLoggedIn
                              ? value.get_first_name_or_username()
                              : "Guest"}{" "}
                            <span class="sr-only">(current)</span>
                          </Link>
                        )
                      ) : (
                        // Change this to update profile button
                        <a
                          class="fontB fontregular btn btn-outline-success my-2 my-sm-0"
                          href="/update_profile"
                        >
                          <span class="fa fa-user-circle"></span> Welcome{" "}
                          {value.isLoggedIn
                            ? value.get_first_name_or_username()
                            : "Guest"}{" "}
                          <span class="sr-only">(current)</span>
                        </a>
                      )}
                    </li>
                    <li class="fontB nav-item active">
                      <Link class="fontB fontregular nav-link" to="/">
                        <span class="fa fa-home"></span> Home{" "}
                        <span class="sr-only"> (current) </span>{" "}
                      </Link>
                    </li>
                    <li class="fontB nav-item active">
                      <a
                        class="fontB fontregular btn btn-outline-info my-2 my-sm-0"
                        href={
                          "https://brown.co1.qualtrics.com/jfe/form/SV_cOMTf4CUsSf5iyp"
                        }
                      >
                        <span class="fa fa-comment"></span> Feedback!{" "}
                        <span class="sr-only"> (current) </span>{" "}
                      </a>
                    </li>
                    <li id="dialog-confirm" class="fontB nav-item active">
                      {value.isGuest && this.state.savable_progress ? (
                        <a
                          className="delete-button"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Are you sure? You can save your guest progress first, before logging out"
                              )
                            )
                              value.logout();
                          }}
                          class="fontB fontregular nav-link"
                          href="javascript:void(0);"
                        >
                          <span class="fa fa-sign-out"></span> Logout
                          <span class="sr-only">(current)</span>
                        </a>
                      ) : (
                        <a
                          className="delete-button"
                          onClick={value.logout}
                          class="fontB fontregular nav-link"
                          href="javascript:void(0);"
                        >
                          <span class="fa fa-sign-out"></span> Logout
                          <span class="sr-only">(current)</span>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </nav>
              <div>
                {this.state.user_profile_info.just_finished_first_quiz &&
                !this.state.closeFeedbackModal &&
                this.state.language != "twi" ? (
                  <RenderFeedbackModal
                    isOpen={true}
                    onClickFunc={this.feedbackAlert}
                    onClickFuncArg={true}
                    closeFunc={this.closeFeedbackAlert}
                  />
                ) : (
                  <div></div>
                )}
              </div>
              {/* {this.displayUpdateProfileModal()} */}
              <div>
                <div className="buttondiv"></div>
                <div>{this.returnSkillTreeByLanguage()}</div>
              </div>
            </div>
          )}
        </UserConsumer>
      </Switch>
    );
  }
}
export default Selectcourse;
