import React, { Component } from "react";
import bodyparts from "./components/app_content/navigation_pages_content/images/bodyparts.jpg";
import profession from "./components/app_content/navigation_pages_content/images/pharmacist.jpg";
import homenouns_image from "./components/app_content/navigation_pages_content/images/homenouns.jpg";
import homeverbs_image from "./components/app_content/navigation_pages_content/images/homeverbs.jpg";
import animals_image from "./components/app_content/navigation_pages_content/images/animals.jpg";
import colors_image from "./components/app_content/navigation_pages_content/images/colors.jpg";
import family_image from "./components/app_content/navigation_pages_content/images/family.jpg";
import chores_image from "./components/app_content/navigation_pages_content/images/chores.jpg";
import medicalnouns_image from "./components/app_content/navigation_pages_content/images/medicine_welcome1.png";
import romance_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import market from "./components/app_content/navigation_pages_content/images/market.png";
import greetings_image from "./components/app_content/navigation_pages_content/images/greetings.jpg";
import bar from "./components/app_content/navigation_pages_content/images/bar.png";
import at_school_2_image from "./components/app_content/navigation_pages_content/images/teacher.png";
import teaching_image from "./components/app_content/navigation_pages_content/images/mathteacher.png";
import farmer_image from "./components/app_content/navigation_pages_content/images/okuani.png";
import certified from "./components/app_content/navigation_pages_content/images/certified.png";

import ReactPixel from "react-facebook-pixel";
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

const GroupItem = ({ info, ctx }) => {
  const quiz_info = ctx.state.swahili_quiz_association_info[info];
  const url_route = quiz_info[0];
  const image = quiz_info[1];
  const name = quiz_info[2];
  const display = ctx.quiz_info(info);
  return ctx.props.returnQuiz(url_route, image, name, display);
};
const DiamondDisplay = (group_items, ctx) => {
  var top = "";
  var left = "";
  var right = "";
  var down = "";
  if (group_items.length > 0) {
    top = <GroupItem info={group_items[0]} ctx={ctx} />;
  }
  if (group_items.length > 1) {
    left = <GroupItem info={group_items[1]} ctx={ctx} />;
  }
  if (group_items.length > 2) {
    right = <GroupItem info={group_items[2]} ctx={ctx} />;
  }
  if (group_items.length > 3) {
    down = <GroupItem info={group_items[3]} ctx={ctx} />;
  }
  return (
    <label id="courserow">
      <label>{top}</label>
      <label>
        {left}
        {right}
      </label>
      <label>{down}</label>
    </label>
  );
};
const ListOfGroupItems = ({ group_items, ctx }) => {
  return <ul>{DiamondDisplay(group_items, ctx)}</ul>;
};
const ListOfGroups = ({ groups, ctx }) => {
  return (
    <ul>
      {groups.map((single_group) => (
        <ListOfGroupItems group_items={single_group} ctx={ctx} />
      ))}
    </ul>
  );
};
class SwahiliSkillTree extends Component {
  constructor(props) {
    super(props);
    this.returnSwahiliSkillTree = this.returnSkillTree.bind(this);
    this.getCompletionStats = this.getCompletionStats.bind(this);
    this.state = {
      swahili_lesson_display_groups: [
        ["greetings_vocab"],
        ["wellbeing_swahili", "family"],
        ["romance"],
        ["home_verbs_1"],
        ["market_food_1_vocab", "at_the_bar_vocab"],
        ["professions"],
        ["home_nouns_1"],
        ["animals"],
        ["medical_nouns_1_vocab", "body_parts"],
        ["doing_chores"],
        ["market_food_2", "colors"],
        ["romance_2"],
        ["professions_2", "at_school_1", "colors_2"],
        ["animals_2"],
        ["at_school_3", "doing_chores_2"],
      ],
      swahili_quiz_association_info: {
        // key must match quiz name in
        // swahili_lesson_display_groups.
        wellbeing_swahili: [
          "swahili-wellbeing-quiz",
          certified,
          "How are you?",
        ],
        romance_2: ["swahili-romance_2_quiz", romance_image, "Romance 2"],
        professions_2: [
          "swahili-professions_2_quiz",
          farmer_image,
          "Professions 2",
        ],
        at_school_1: [
          "swahili-at_school_1_quiz",
          at_school_2_image,
          "Education 1",
        ],
        colors_2: ["swahili-colors_2_quiz", colors_image, "Colors 2"],
        animals_2: ["swahili-animals_2_quiz", animals_image, "Animals-2"],
        at_school_3: [
          "swahili-at_school_3_quiz",
          teaching_image,
          "Education 2",
        ],
        doing_chores_2: [
          "swahili-chores_2_quiz",
          chores_image,
          "Doing Chores 2",
        ],

        medical_nouns_1_vocab: [
          "swahili-medicalnouns-quiz",
          medicalnouns_image,
          "Medical Nouns",
        ],
        at_the_bar_vocab: ["swahili-atthebar-quiz", bar, "At The Bar"],

        professions: ["/swahili-professions-quiz", profession, "Professions"],
        market_food_2: ["swahili-marketfood-2-quiz", market, "Market Food 2"],
        market_food_1_vocab: [
          "swahili-marketfood-quiz",
          market,
          "At The Market",
        ],

        home_nouns_1: [
          "swahili-course-homenouns_quiz",
          homenouns_image,
          "Home Nouns",
        ],

        home_verbs_1: ["swahili-homeverbs-quiz", homeverbs_image, "Home Verbs"],

        greetings_vocab: [
          "swahili-course-greetings-quiz",
          greetings_image,
          "Greetings",
        ],
        family: ["swahili-family-quiz", family_image, "Family"],
        romance: ["swahili-romance_quiz", romance_image, "Romance"],

        animals: ["swahili-animals_quiz", animals_image, "Animals"],

        body_parts: ["swahili-bodyparts-quiz", bodyparts, "Body Parts"],

        doing_chores: ["swahili-chores_quiz", chores_image, "Doing Chores"],

        colors: ["swahili-colors_quiz", colors_image, "Colors"],
      },
      swahili_non_limited_quizzes: new Set([
        "home_nouns_1",
        "wellbeing_swahili",
        "market_food_1_vocab",
        "greetings_vocab",
      ]),
      // language_name : 'ga' // this.props.language_name
      // skill tree state info
      swahili_playable_quizzes_set: new Set([
        "home_nouns_1",
        "medical_nouns_1_vocab",
        "professions",
        "market_food_1_vocab",
        "home_verbs_1",
        "market_food_2",
        "family",
        "at_the_bar_vocab",
        "body_parts",
        "romance",
        "animals",
        "doing_chores",
        "wellbeing_swahili",
        "greetings_vocab",
        // "colors",
      ]),
      lesson_stats: {},
    };
  }

  componentDidMount() {
    this.getCompletionStats(this.state.swahili_playable_quizzes_set);
  }

  getCompletionStats(quiz_names) {
    this.props
      .getCompletionStats(quiz_names)
      .then((newItems) => this.setState({ lesson_stats: newItems }));
  }

  quiz_info(quiz_name) {
    const in_tree = this.state.swahili_playable_quizzes_set.has(quiz_name);
    const stats = this.state.lesson_stats[quiz_name];
    const limited = !this.state.swahili_non_limited_quizzes.has(quiz_name);
    var quiz_info_arr = [quiz_name, in_tree, stats, limited];
    return quiz_info_arr;
  }

  returnSkillTree() {
    return (
      <div className="courselineditems">
        <ListOfGroups
          groups={this.state.swahili_lesson_display_groups}
          ctx={this}
        />
      </div>
    );
  }

  render() {
    return <div>{this.returnSkillTree()}</div>;
  }
}
export default SwahiliSkillTree;
