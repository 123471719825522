import React, { Component } from "react";
import ChoicesMispell from "./ChoicesMispell";
import sbimage from "./app_content/navigation_pages_content/images/speakerwhitebg.png";

class Questionthree extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.playQuestionAudio = this.playQuestionAudio.bind(this);
    this.state = {
      // questions: [
      //     {
      //       id: 1,
      //       image: hy,
      //       example: 'hy as in hyi (burn)',
      //       audio: hyaudio,
      //       text: 'Select the words that sound out this digraph?',
      //       choices: [
      //         {
      //           id: 'a',
      //           text: 'jam / gym',
      //           picture:'',
      //           audio:'',
      //         },
      //         {
      //           id: 'b',
      //           text: 'ship / shorts',
      //           picture:'',
      //           audio:'',
      //         },
      //         {
      //           id: 'c',
      //           text: 'queen / quick',
      //           picture:'',
      //           audio:'',
      //         }
      //       ],
      //       correct: 'b'
      //     },
      //   {
      //       id: 2,
      //       image: gy,
      //       example: 'gy as in gyata (lion)',
      //       audio: gyaudio,
      //       text: 'Select the words that sound out this digraph?',
      //       choices: [
      //         {
      //         id:'a' ,
      //         text: 'goal / game' ,
      //         picture:'',
      //         audio:'',
      //         },
      //         {
      //         id: 'b' ,
      //         text: 'jam / gym',
      //         picture:'',
      //         audio: '',
      //         },
      //         {
      //         id: 'c' ,
      //         text: 'canyon / onion',
      //         picture: '' ,
      //         audio: '',
      //         }
      //         ],
      //     correct: 'b'
      //   },
      //   {
      //       id: 3,
      //       image: kw,
      //       example: 'kw as in kwadu (banana)',
      //       audio: kwaudio,
      //       text: 'Select the words that sound out this digraph?',
      //       choices: [
      //         {
      //         id:'a' ,
      //         text: 'queen / quick' ,
      //         picture:'',
      //         audio:'',
      //         },
      //         {
      //         id: 'b' ,
      //         text: 'check / chain',
      //         picture:'',
      //         audio:'',
      //         },
      //         {
      //         id: 'c' ,
      //         text: 'king / kilt',
      //         picture:'' ,
      //         audio:'',
      //         }
      //         ],
      //     correct: 'a'
      //   },
      //   {
      //       id: 4,
      //       image: ny,
      //       example: 'ny as in nyansa (wisdom)',
      //       audio: nyaudio,
      //       text: 'Select the words that sound out this digraph?',
      //       choices: [
      //         {
      //         id:'a' ,
      //         text: 'name / noun' ,
      //         picture:'',
      //         audio:'',
      //         },
      //         {
      //         id: 'b' ,
      //         text: 'canyon / onion',
      //         picture:'',
      //         audio:'',
      //         },
      //         {
      //         id: 'c' ,
      //         text: 'near / nail',
      //         picture:'',
      //         audio:'',
      //         }
      //         ],
      //     correct: 'b'
      //   },
      //   {
      //       id: 5,
      //       image: ky,
      //       example: 'ky as in kyiniiɛ (umbrella)',
      //       audio: kyaudio,
      //       text: 'Select the words that sound out this digraph?',
      //       choices: [
      //         {
      //         id:'a' ,
      //         text: 'queen / quick' ,
      //         picture:'',
      //         audio:'',
      //         },
      //         {
      //         id: 'b' ,
      //         text: 'king / kilt',
      //         picture:'',
      //         audio:'',
      //         },
      //         {
      //         id: 'c' ,
      //         text: 'check / chain',
      //         picture:'',
      //         audio:'',
      //         }
      //         ],
      //     correct: 'c'
      //   },
      // ]//end of questions//question close bracket
    }; //state close
  } //constructor close

  componentDidMount() {
    return setTimeout(
      function () {
        this.playQuestionAudio();
      }.bind(this),
      850
    );
  }
  setQuestionAudio = () => {
    var question_audio_url = "";
    var question = this.props.question;
    if (this.props.auto_generated_choices) {
      // console.log("quad:1");
      if (!this.props.using_african_word) {
        // console.log("quad:1a");
        question_audio_url = question.word.twi_audio_url;
      } else {
        // console.log("quad:1b: qobj: ", question);
        question_audio_url = question.african_word.native_audio_url;
      }
    } else {
      // console.log("quad:2");
      question_audio_url = question.text_audio_url;
    }
    return question_audio_url;
  };
  playQuestionAudio() {
    var question_audio_url = this.setQuestionAudio();
    this.myaudio.src = question_audio_url;
    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  getChoiceImage(choice) {
    var choiceword = choice.word;
    var image_url = choiceword.image_url;
    if (this.state.using_african_word) {
      choiceword = choice.african_word;

      // use general image unless there is a
      // language specific image
      if (choiceword.has_native_image) {
        image_url = choiceword.native_image_url;
      }
    }
    return image_url;
  }
  render() {
    var question_text = "";
    var question_image_url = "";
    if (this.props.auto_generated_choices) {
      question_text = this.props.question.question_instruction;
      question_image_url = this.props.question.word.image_url;
    } else {
      question_text = this.props.question.text;
      question_image_url = this.props.question.image_url;
    }

    // Add support to show native image for mispell if general image doesn't exist
    if (
      !question_image_url &&
      this.props.using_african_word &&
      this.props.question.african_word
    ) {
      question_image_url = this.props.question.african_word.native_image_url;
    }
    return (
      <div>
        <p className="questiontext_three">{question_text}</p>
        <div className="arrangequestion">
          <div className="questionimages">
            <div>
              <label onClick={this.playQuestionAudio}>
                <img id="qimage" src={question_image_url} />
                <img id="sbimispell" src={sbimage} />
              </label>
            </div>
          </div>
          <div id="choiceslistmispell">
            <ChoicesMispell
              incQN={this.props.incQN}
              incS={this.props.incS}
              currentQuestion={this.props.currentQuestion}
              questionObject={this.props.question}
              questionText={question_text}
              idx={this.props.question.question_number}
              setSelectedChoice={this.props.setSelectedChoice}
              setCurrentQuestionObject={this.props.setCurrentQuestionObject}
              answerSubmitted={this.props.answerSubmitted}
              auto_generated_choices={this.props.auto_generated_choices}
              using_african_word={this.props.using_african_word}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Questionthree;
