import React, { Component } from "react";
import bodyparts from "./components/app_content/navigation_pages_content/images/bodyparts.jpg";
import profession from "./components/app_content/navigation_pages_content/images/pharmacist.jpg";
import homenouns_image from "./components/app_content/navigation_pages_content/images/homenouns.jpg";
import homeverbs_image from "./components/app_content/navigation_pages_content/images/homeverbs.jpg";
import animals_image from "./components/app_content/navigation_pages_content/images/animals.jpg";
import colors_image from "./components/app_content/navigation_pages_content/images/colors.jpg";
import family_image from "./components/app_content/navigation_pages_content/images/family.jpg";
import chores_image from "./components/app_content/navigation_pages_content/images/chores.jpg";
import medicalnouns_image from "./components/app_content/navigation_pages_content/images/medicine_welcome1.png";
import romance_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import market from "./components/app_content/navigation_pages_content/images/market.png";
import greetings_image from "./components/app_content/navigation_pages_content/images/greetings.jpg";
import farmer_image from "./components/app_content/navigation_pages_content/images/okuani.png";
import at_school_2_image from "./components/app_content/navigation_pages_content/images/teacher.png";
import bar from "./components/app_content/navigation_pages_content/images/bar.png";
import rubiks_image from "./components/app_content/navigation_pages_content/images/rubiks.png";

import ReactPixel from "react-facebook-pixel";
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

const GroupItem = ({ info, ctx }) => {
  const quiz_info = ctx.state.yoruba_quiz_association_info[info];
  const url_route = quiz_info[0];
  const image = quiz_info[1];
  const name = quiz_info[2];
  const display = ctx.quiz_info(info);
  return ctx.props.returnQuiz(url_route, image, name, display);
};
const DiamondDisplay = (group_items, ctx) => {
  var top = "";
  var left = "";
  var right = "";
  var down = "";
  if (group_items.length > 0) {
    top = <GroupItem info={group_items[0]} ctx={ctx} />;
  }
  if (group_items.length > 1) {
    left = <GroupItem info={group_items[1]} ctx={ctx} />;
  }
  if (group_items.length > 2) {
    right = <GroupItem info={group_items[2]} ctx={ctx} />;
  }
  if (group_items.length > 3) {
    down = <GroupItem info={group_items[3]} ctx={ctx} />;
  }
  return (
    <label id="courserow">
      <label>{top}</label>
      <label>
        {left}
        {right}
      </label>
      <label>{down}</label>
    </label>
  );
};
const ListOfGroupItems = ({ group_items, ctx }) => {
  return <ul>{DiamondDisplay(group_items, ctx)}</ul>;
};
const ListOfGroups = ({ groups, ctx }) => {
  return (
    <ul>
      {groups.map((single_group) => (
        <ListOfGroupItems group_items={single_group} ctx={ctx} />
      ))}
    </ul>
  );
};
class YorubaSkillTree extends Component {
  constructor(props) {
    super(props);
    this.returnYorubaSkillTree = this.returnSkillTree.bind(this);
    this.getCompletionStats = this.getCompletionStats.bind(this);
    this.state = {
      yoruba_lesson_display_groups: [
        ["home_nouns_1"],
        ["greetings_yoruba", "family"],
        ["naija_artists_puzzle"],
        ["market_food_1_vocab"],
        ["professions", "medical_nouns_1_vocab"],
        ["home_verbs_1"],
        ["doing_chores"],
        ["body_parts", "at_the_bar_vocab"],
        ["animals"],
        ["romance"],
         // upcoming
        ["colors","at_school_1"],
        ["colors_2"],
        // ["doing_chores_2", "animals_2"],
        // ["at_school_1", "at_home_2", "romance_2"],
        // ["at_school_3", "colors_2"],
        ["romance_2", "body_parts_2"],
        ["professions_2"],

        ["doing_chores_2", "animals_2", "at_school_2"],
        // ["professions"],
        // ["home_nouns_1", "medical_nouns_1"],
        // ["medical_nouns_1", "professions", "market_food_1"],
        // ["home_nouns_1", "professions", "market_food_1", "medical_nouns_1"]
      ],
      yoruba_quiz_association_info: {
        // key must match quiz name in
        // yoruba_lesson_display_groups.
        naija_artists_puzzle: [
          "naija-artists-puzzle-quiz",
          rubiks_image,
          "Puzzle: Afrobeats",
        ],
        at_the_bar_vocab: ["yoruba-atthebar-quiz", bar, "At The Bar"],
        romance_2: ["yoruba-romance-2-quiz", romance_image, "Romance 2"],
        colors_2: ["yoruba-colors-2-quiz", colors_image, "Colors 2"],
        professions_2: [
          "yoruba-professions-2-quiz",
          farmer_image,
          "Professions 2",
        ],
        at_school_1: [
          "yoruba-at-school-1-quiz",
          at_school_2_image,
          "Education 1",
        ],
        body_parts_2: ["yoruba-bodyparts-2-quiz", bodyparts, "Body Parts 2"],
        doing_chores_2: [
          "yoruba-chores-2-quiz",
          chores_image,
          "Doing Chores 2",
        ],
        animals_2: ["yoruba-animals-2-quiz", animals_image, "Animals 2"],
        at_school_2: [
          "yoruba-at-school-2-quiz",
          at_school_2_image,
          "Education 2",
        ],
        medical_nouns_1_vocab: [
          "yoruba-medicalnouns-quiz",
          medicalnouns_image,
          "Medical Nouns",
        ],

        professions: [
          "/yoruba-course-professions_quiz",
          profession,
          "Professions",
        ],

        market_food_1_vocab: [
          "yoruba-marketfood-quiz",
          market,
          "At The Market",
        ],

        home_nouns_1: [
          "yoruba-course-homenouns_quiz",
          homenouns_image,
          "Home Nouns",
        ],

        home_verbs_1: [
          "yoruba-course-homeverbs-quiz",
          homeverbs_image,
          "Home Verbs",
        ],

        greetings_vocab: [
          "yoruba-course-greetings-vocab-quiz",
          greetings_image,
          "Greetings",
        ],
        greetings_yoruba: [
          "yoruba-greetings-quiz",
          greetings_image,
          "Greetings 1",
        ],

        romance: ["yoruba-romance-quiz", romance_image, "Romance"],

        family: ["yoruba-course-family-quiz", family_image, "Family"],

        animals: ["yoruba-animals-quiz", animals_image, "Animals"],

        body_parts: ["yoruba-bodyparts-quiz", bodyparts, "Body Parts"],

        doing_chores: ["yoruba-chores-quiz", chores_image, "Doing Chores"],

        colors: ["colors_quiz", colors_image, "Colors"],
      },
      // language_name : 'yoruba' // this.props.language_name
      // skill tree state info
      yoruba_non_limited_quizzes: new Set([
        "greetings_yoruba",
        "home_nouns_1",
        "professions",
      ]),
      yoruba_playable_quizzes_set: new Set([
        "naija_artists_puzzle",
        "home_nouns_1",
        "medical_nouns_1_vocab",
        "professions",
        "market_food_1_vocab",
        "home_verbs_1",
        "greetings_yoruba",
        "family",
        "body_parts",
        "at_the_bar_vocab",
        "doing_chores",
        "animals",
        "romance",
      ]),
      lesson_stats: {},
    };
  }

  componentDidMount() {
    this.getCompletionStats(
      this.state.lesson_stats,
      this.state.yoruba_playable_quizzes_set
    );
  }

  getCompletionStats(lesson_stats, quiz_names) {
    this.props
      .getCompletionStats(quiz_names)
      .then((newItems) => this.setState({ lesson_stats: newItems }));
  }

  quiz_info(quiz_name) {
    const in_tree = this.state.yoruba_playable_quizzes_set.has(quiz_name);
    const stats = this.state.lesson_stats[quiz_name];
    const limited = !this.state.yoruba_non_limited_quizzes.has(quiz_name);
    var quiz_info_arr = [quiz_name, in_tree, stats, limited];
    return quiz_info_arr;
  }

  returnSkillTree() {
    return (
      <div className="courselineditems">
        <ListOfGroups
          groups={this.state.yoruba_lesson_display_groups}
          ctx={this}
        />
      </div>
    );
  }

  render() {
    return <div>{this.returnSkillTree()}</div>;
  }
}
export default YorubaSkillTree;
