import React, { Component } from "react";
import ChoicesMCSentence from "./ChoicesMCSentence.js";

class QuestionMCSentence extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.playQuestionAudio = this.playQuestionAudio.bind(this);
    this.state = {
      question_type_text: this.props.question_type_text,
      stateaudio: "",
    }; //state close
  }

  playQuestionAudio() {
    this.myaudio.src = this.state.stateaudio;
    this.myaudio.play();
  }

  render() {
    return (
      <div>
        {/* <head>
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
            integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
            crossorigin="anonymous"
          />
        </head> */}
        <p class="questiontext_three">{this.state.question_type_text}</p>
        <br />
        <div className="arrange_sentence_question">
          <div className="questionimagesMC">
            <div>
              <label onClick={this.playQuestionAudio}>
                <p id="question_translate_text"> {this.props.question.text} </p>
              </label>
            </div>
          </div>
          <div id="choiceslist">
            <ChoicesMCSentence
              incQN={this.props.incQN}
              incS={this.props.incS}
              currentQuestion={this.props.currentQuestion}
              questionObject={this.props.question}
              questionText={this.state.question_type_text}
              idx={this.props.question.id}
              setSelectedChoice={this.props.setSelectedChoice}
              setCurrentQuestionObject={this.props.setCurrentQuestionObject}
              answerSubmitted={this.props.answerSubmitted}
              auto_generated_choices={this.props.auto_generated_choices}
              using_african_word={this.props.using_african_word}
            />
          </div>
          <br />
        </div>
      </div>
    );
  }
}

export default QuestionMCSentence;
