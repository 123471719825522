import React, { Component } from "react";
// import Choices   from './Choices'
import sbimage from "./app_content/navigation_pages_content/images/speakerwhitebg.png";
import "./Choices.css";
// import { Popup, Card, Rating, Image } from 'semantic-ui-react'

class Teachtranslation extends Component {
  constructor(props) {
    super(props);
    this.myaudio = new Audio();
    this.state = {
      mute: false,
      stateaudio: "",
    }; //state close
    this.getColor = this.getColor.bind(this);
    this.playQuestionAudio = this.playQuestionAudio.bind(this);
  } //constructor close

  componentDidMount() {
    console.log("componentDidMount teach translation");
    return setTimeout(
      function () {
        console.log("cdm teach translate setTimeout called");
        this.playQuestionAudio();
      }.bind(this),
      850
    );
  }
  componentWillUnmount() {}
  playQuestionAudio() {
    //this.myaudio.pause();
    console.log("teach translation play sound");
    this.state.stateaudio = this.props.questionObject.text_audio_url;
    this.myaudio.src = this.state.stateaudio;

    var promise = this.myaudio.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }
  getColor(option) {
    if (option === "a") {
      return "red_word";
    } else if (option === "b") {
      return "yellow_word";
    } else if (option === "c") {
      return "blue_word";
    } else if (option === "d") {
      return "green_word";
    } else if (option === "e") {
      return "orange_word";
    }
  }
  render() {
    var space = " ";
    return (
      <div>
        <head>
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
            integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
            crossorigin="anonymous"
          />
        </head>

        <div>
          {<p class="questiontext_three"> Study the color-coded translation</p>}
        </div>
        <br />
        <div className="translatecardtop"></div>
        <div class="translatecard" onClick={this.playQuestionAudio}>
          {
            <div>
              <div id="question4info2" class="horizontal_coded">
                <div>
                  <p class="horizontal_coded">
                    {" "}
                    <img
                      id="sbitranslate_coded"
                      onClick={this.playQuestionAudio}
                      src={sbimage}
                    />
                  </p>
                  {this.props.questionObject.choices.map((choice) => {
                    return (
                      <p
                        class="horizontal_coded"
                        id={this.getColor(choice.multiple_choice_option)}
                      >
                        {choice.word.twi_text}&nbsp;
                      </p>
                    );
                  })}
                </div>
              </div>
              <div>
                <div id="question4info2" class="horizontal">
                  {this.props.questionObject.choices.map((choice) => {
                    return (
                      <p
                        class="horizontal"
                        id={this.getColor(choice.multiple_choice_option)}
                      >
                        {choice.word.english_text}&nbsp;
                      </p>
                    );
                  })}
                  {this.props.questionObject.choices.map((choice) => {
                    return (
                      <p
                        class="horizontal"
                        id={this.getColor(choice.multiple_choice_option)}
                      ></p>
                    );
                  })}
                </div>
                <div id="question4info2XXXXX" class="uniform_style">
                  <p id="blue_text">{this.props.questionObject.text}</p>
                </div>
              </div>
            </div>
          }
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default Teachtranslation;
